import React, { useContext, useEffect, useState } from "react";
import Styles from "./HiOperationsCreateFile.module.css"
import { getDownloadURL, ref as storageRef, uploadBytesResumable } from "firebase/storage";
import { Button, Divider, MenuItem, Modal, Select, Tab, Tabs } from "@mui/material";
import { FaExternalLinkSquareAlt } from 'react-icons/fa';
import { onValue, push, ref, remove, set } from "firebase/database";
import { firebaseDatabase, firebaseDatabaseSC, firebaseStorage } from "../../../backend/firebaseHandler";
import moment from "moment";
import { toast } from "react-toastify";
import { checkStatus } from "../../../workload/handlePotentialCrossSales";
import { getAdvisorUid } from "../../../workload/getAdvisorUid";
import CustomAutoComplete from "../../CustomAutoComplete/CustomAutoComplete.component";
import InputContainer from "../../InputContainer/InputContainer.component";
import getHiDocumentMap from "../../../workload/HiDocumentSet";
import UserContext from "../../../context/UserData.context";
import HealthPremiumMap from "../../../workload/HealthPremiumMap";

const HiOperationsCreateFile = () => {
    
    const [currentTab, setCurrentTab] = useState("Applicant Details");
    const [application, setApplication] = useState({});
    const [submitting, setSubmitting] = useState(false);
    const [userData] = useContext(UserContext)

    useEffect(() => {
        let tempDate = moment().format("DD-MM-YYYY h:mm a")
        let crmObject = {
            key: push(ref(firebaseDatabase, "INTERNALLY_CREATED_LEADS")).key,
            customerId: Date.now().toString(),
            uidSC: "",
            source: "Internal",
            sourceDescription: userData.uid,
            type: "INBOUND",
            date: tempDate,
            status: "Operations",
            subStatus: "New",
            product: "Health Insurance",
            receivedFrom: {position: "Operations", name: userData.name, phoneNumber: userData.phoneNumber, uid: userData.uid},
            newFromAdminDate: tempDate,
            selectedBusiness: "Student Cover",
            selectedPlanName: "",
            selectedPlanId: "",
            provider: "",
            paidFee: "",
            visaCategory: "International Student on F1 Visa",
            sentToOperationsDate: tempDate
        }

        setApplication(crmObject)
    }, [])

    const handleTabChange = (_, newValue) => {
        setCurrentTab(newValue)
    }

    const handleDocumentUpload = (item, folderName, index, key) => {
        const inputElement = document.createElement('input');
        inputElement.setAttribute('type', "file");
        inputElement.setAttribute('accept', 'application/pdf');

        inputElement.onchange = (event) => {
            const files = event.target.files;

            if (files[0].name.split(".")[1] !== "pdf") {
                toast("Please upload a PDF file.")
            } else {
                const fileRef = storageRef(firebaseStorage, `HEALTH_INSURANCE_DOCS/${application.key}/${folderName}/${item.label}/${Date.now().toString()}.pdf`);
                setSubmitting(true)
                const uploadTask = uploadBytesResumable(fileRef, files[0], { contentType: 'application/pdf' });
                uploadTask.on('state_changed', (snapshot) => {

                }, (error) => {
                    toast("Something went wrong")
                }, async () => {
                    const url = await getDownloadURL(fileRef);
                    item.url = url
                    let tempObj = { ...application }
                    tempObj[key][index] = { ...item }
                    setApplication({ ...tempObj })
                    setSubmitting(false)
                })
            }
        }
        inputElement.click();
    }

    const handleForward = async (finalApplication) => {
        try {
            setSubmitting(true);
            const status = await checkStatus(finalApplication.phoneNumber);

            if (status === "Fresh") {
                await handleFresh(finalApplication)
            } else if (status === "New") {
                await handleRest(finalApplication, "New", "HI_NEW_LEADS").then();
                toast.success("Sent to operations!");
            } else if (status === "Interested") {
                await handleRest(finalApplication, "Interested", "HI_INTERESTED_LEADS").then();
                toast.success("Sent to operations!");
            } else if (status === "Rescheduled") {
                await handleRest(finalApplication, "Rescheduled", "HI_RESCHEDULED_LEADS").then();
                toast.success("Sent to operations!");
            } else if (status === "Closed") {
                await handleRest(finalApplication, "Closed", "HI_CLOSED_LEADS").then();
                toast.success("Sent to operations!");
            } else {
                toast.warn("This application is already under progress");
            }
            setSubmitting(false);
        } catch (err) {
            setSubmitting(false);
            toast.error("Already in process");
        }
    }

    const getProfileStatus = () => {
        let isComplete = true

        if (!application.name) {
            isComplete = false
        }
        if (!application.dob) {
            isComplete = false
        }
        if (!application.visaCategory) {
            isComplete = false
        }
        if (!application.gender) {
            isComplete = false
        }
        if (!application.travelDate) {
            isComplete = false
        }

        if (!application.currentCountry) {
            isComplete = false
        }
        if (!application.panNumber) {
            isComplete = false
        }
        if (!application.sponsorName) {
            isComplete = false
        }
        if (!application.emergencyNumber) {
            isComplete = false
        }
        if (!application.alternateEmail) {
            isComplete = false
        }
        // if (!application.motherName) {
        //     isComplete = false
        // }
        // if (!application.passportNumber) {
        //     isComplete = false
        // }
        // if (!application.nomineeName) {
        //     isComplete = false
        // }
        // if (!application.nomineeRelation) {
        //     isComplete = false
        // }
        // if (!application.nomineeDOB) {
        //     isComplete = false
        // }

        // if (application.haveUsAddress === "Yes" && !application.usAddress) {
        //     isComplete = false
        // }

        // if (!application.applicantDocs) {
        //     isComplete = false
        // }

        // for (const index in application.applicantDocs) {
        //     if (application.applicantDocs[index].url === "") {
        //         isComplete = false
        //     }
        // }

        return isComplete
    }

    const handelInIndia = (value) => {
        let tempObj = { ...application }

        tempObj.currentCountry = value;
        const hiDocumentMap = getHiDocumentMap();
        tempObj.applicantDocs = value === "India" ? [...hiDocumentMap["StudentInIndiaICICI"]] : [...hiDocumentMap["StudentNotInIndia"]]

        setApplication({ ...tempObj })
    }

    const handleFresh = async (finalApplication) => {
        try {
            let updateObject = {}
            updateObject.status = "Operations"
            let array = []
            let arrayItem = {
                actionBy: userData.uid,
                status: "Operations",
                subStatus: "New",
                date: finalApplication.sentToOperationsDate,
                ref: "INTERNALLY_CREATED_LEADS"
            }
            array.push(arrayItem)
            updateObject.lifeline = [...array]

            if (finalApplication.phoneNumber !== "" && finalApplication.phoneNumber !== null && finalApplication.phoneNumber !== undefined) {
                await set(ref(firebaseDatabase, `HEALTH_INSURANCE_APPLICATIONS/${finalApplication.phoneNumber}`), finalApplication)
                await set(ref(firebaseDatabase, `INTERNALLY_CREATED_LEADS/${finalApplication.phoneNumber}`), finalApplication)
                await set(ref(firebaseDatabase, `ALL_LEADS_STATUS/Health Insurance/${finalApplication.phoneNumber}`), updateObject)
                toast.success("File added.")
                setInterval(() => {
                    window.location.reload()
                }, 2000)
                
            } else {
                alert ("Something went wrong.")
            }
            
        } catch (err) {
            console.log(err);
        }

    }

    const handleRest = async (finalApplication, currentStatus, table) => {
        const completeNewOperation = new Promise((resolve, reject) => {

            onValue(ref(firebaseDatabase, `ALL_LEADS_STATUS/Health Insurance/${finalApplication.phoneNumber}`), async (snap) => {
                if (snap.exists()) {
                    try {
                        let updateObject = snap.val()
                        updateObject.status = "Operations"
                        let array = [...updateObject.lifeline]
                        let arrayItem = {
                            actionBy: userData.uid,
                            status: "Operations",
                            subStatus: "New",
                            date: finalApplication.date,
                            ref: "INTERNALLY_CREATED_LEADS"
                        }
                        array.push(arrayItem)
                        updateObject.lifeline = [...array]

                        const advisorUid = await getAdvisorUid(finalApplication.phoneNumber).then();
                        finalApplication.hiAdvisorUid = advisorUid;
                        finalApplication.hiStatus = currentStatus;
                        
                        console.log(finalApplication, updateObject)
                        if (finalApplication.phoneNumber && finalApplication.hiAdvisorUid && table && advisorUid) {
                            await set(ref(firebaseDatabase, `ALL_LEADS_STATUS/Health Insurance/${finalApplication.phoneNumber}`), updateObject)
                            await set(ref(firebaseDatabase, `HEALTH_INSURANCE_APPLICATIONS/${finalApplication.phoneNumber}`), finalApplication)
                            await set(ref(firebaseDatabase, `INTERNALLY_CREATED_LEADS/${finalApplication.phoneNumber}`), finalApplication)
                            await remove(ref(firebaseDatabase, `${table}/${advisorUid}/${finalApplication.phoneNumber}`));
                            resolve();
                            // toast.success("File added.")
                            setInterval(() => {
                                window.location.reload()
                            }, 2000)
                        } else {
                            alert("Something went wrong.")
                            reject();
                        }
                        
                    } catch (err) {
                        reject();
                    }

                } else {
                    reject();
                }
            }, { onlyOnce: true })
        })

        return await completeNewOperation.then()
    }

    const handleForwardF1 = async (finalApplication) => {
        try {
            setSubmitting(true);
            const status = await checkStatus(finalApplication.phoneNumber);

            if (status === "Fresh") {
                await handleF1Fresh(finalApplication)
            } else if (status === "New") {
                await handleF1Rest(finalApplication, "New", "HI_NEW_LEADS").then();
                toast.success("Sent to operations!");
            } else if (status === "Interested") {
                await handleF1Rest(finalApplication, "Interested", "HI_INTERESTED_LEADS").then();
                toast.success("Sent to operations!");
            } else if (status === "Rescheduled") {
                await handleF1Rest(finalApplication, "Rescheduled", "HI_RESCHEDULED_LEADS").then();
                toast.success("Sent to operations!");
            } else if (status === "Closed") {
                await handleF1Rest(finalApplication, "Closed", "HI_CLOSED_LEADS").then();
                toast.success("Sent to operations!");
            } else {
                toast.warn("This application is already under progress");
            }
            setSubmitting(false);
        } catch (err) {
            setSubmitting(false);
            toast.error("Already in process");
        }
    }

    const handleF1Fresh = async (finalApplication) => {
        try {
            let updateObject = {}
            updateObject.status = "Operations"
            let array = []
            let arrayItem = {
                actionBy: userData.uid,
                status: "Operations",
                subStatus: "New",
                date: finalApplication.sentToOperationsDate,
                ref: "INTERNALLY_CREATED_LEADS"
            }
            array.push(arrayItem)
            updateObject.lifeline = [...array]

           
            if (finalApplication.phoneNumber !== "" && finalApplication.phoneNumber !== null && finalApplication.phoneNumber !== undefined) {
                await set(ref(firebaseDatabase, `HEALTH_INSURANCE_APPLICATIONS/${finalApplication.phoneNumber}`), finalApplication)
                await set(ref(firebaseDatabase, `HI_F1_OPT_APPLICATIONS/${finalApplication.phoneNumber}`), finalApplication)
                await set(ref(firebaseDatabase, `ALL_LEADS_STATUS/Health Insurance/${finalApplication.phoneNumber}`), updateObject)
                toast.success("File added.")
                setInterval(() => {
                    window.location.reload()
                }, 2000)
                
            } else {
                alert ("Something went wrong.")
            }
            
        } catch (err) {
            console.log(err);
        }

    }

    const handleF1Rest = async (finalApplication, currentStatus, table) => {
        const completeNewOperation = new Promise((resolve, reject) => {

            onValue(ref(firebaseDatabase, `ALL_LEADS_STATUS/Health Insurance/${finalApplication.phoneNumber}`), async (snap) => {
                if (snap.exists()) {
                    try {
                        let updateObject = snap.val()
                        updateObject.status = "Operations"
                        let array = [...updateObject.lifeline]
                        let arrayItem = {
                            actionBy: userData.uid,
                            status: "Operations",
                            subStatus: "New",
                            date: finalApplication.date,
                            ref: "INTERNALLY_CREATED_LEADS"
                        }
                        array.push(arrayItem)
                        updateObject.lifeline = [...array]

                        const advisorUid = await getAdvisorUid(finalApplication.phoneNumber).then();
                        finalApplication.hiAdvisorUid = advisorUid;
                        finalApplication.hiStatus = currentStatus;
                        
                        console.log(updateObject)
                        console.log(finalApplication)

                        if (finalApplication.phoneNumber && finalApplication.hiAdvisorUid && table && advisorUid) {
                            await set(ref(firebaseDatabase, `ALL_LEADS_STATUS/Health Insurance/${finalApplication.phoneNumber}`), updateObject)
                            await set(ref(firebaseDatabase, `HEALTH_INSURANCE_APPLICATIONS/${finalApplication.phoneNumber}`), finalApplication)
                            await set(ref(firebaseDatabase, `HI_F1_OPT_APPLICATIONS/${finalApplication.phoneNumber}`), finalApplication)
                            await remove(ref(firebaseDatabase, `${table}/${advisorUid}/${finalApplication.phoneNumber}`));
                            resolve();
                            // toast.success("File added.")
                            setInterval(() => {
                                window.location.reload()
                            }, 2000)
                        } else {
                            alert("Something went wrong.")
                            reject();
                        }
                        
                    } catch (err) {
                        reject();
                    }

                } else {
                    reject();
                }
            }, { onlyOnce: true })
        })

        return await completeNewOperation.then()
    }

    return (
        <div className={Styles.crossSaleLeadDetailsContainer}>
            <div>
                <h1>Cross Sale Lead Details</h1>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: 20, marginBottom: 40 }}>
                <Tabs value={currentTab} onChange={handleTabChange}  >
                    <Tab label="Applicant Details" value="Applicant Details" />
                    <Tab label="More Details" value="More Details" />
                    <Tab label="Upload App Docs" value="Upload App Docs" />
                </Tabs>
            </div>

            <div className={Styles.formDocContainer}>
                {
                    currentTab === "Applicant Details"
                    &&
                    <div className={Styles.formContainer}>

                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Name</p>
                            <input value={application.name} onChange={event => setApplication({ ...application, name: event.target.value })} className={Styles.inputField} placeholder="Full Name" InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Date of Birth</p>
                            <input type="date" value={application.dob} onChange={event => setApplication({ ...application, dob: event.target.value })} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Email-Id</p>
                            <input value={application.emailId} onChange={event => setApplication({ ...application, emailId: event.target.value })} className={Styles.inputField} placeholder="Email-Id" InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>

                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Visa Category</p>
                            <Select style={{ width: "100%" }} value={application.visaCategory || ""} onChange={event => setApplication({ ...application, visaCategory: event.target.value })} disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                                <MenuItem value={"International Student on F1 Visa"} >International Student on F1 Visa</MenuItem>
                                <MenuItem value={"F-1 Student on OPT"} >F-1 Student on OPT</MenuItem>
                                <MenuItem value={"Student on J1 Visa"} >Student on J1 Visa</MenuItem>
                                <MenuItem value={"Non Student"} >Non Student</MenuItem>
                                <MenuItem value={"Dependents on F2/J2"} >Dependents on F2/J2</MenuItem>
                            </Select>
                        </div>

                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Gender</p>
                            <Select style={{ width: "100%" }} value={application.gender || ""} onChange={event => setApplication({ ...application, gender: event.target.value })} disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                                <MenuItem value={"Male"} >Male</MenuItem>
                                <MenuItem value={"Female"} >Female</MenuItem>
                                <MenuItem value={"Other"} >Other</MenuItem>
                            </Select>
                        </div>

                        <div style={{ width: "100%", gridColumn: "1/2" }}>
                            <p className={Styles.label}>Phone Number (With country code)</p>
                            <input value={application.phoneNumber} onChange={event => setApplication({ ...application, phoneNumber: event.target.value.replace(/[^0-9]+/g, "") })} className={Styles.inputField} placeholder="" InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>

                        <div className={Styles.yesNoContainer}>
                            <p>Is this whatsapp number?</p>
                            <p style={{ color: application.isThisWhatsappNumber === "Yes" ? "rgba(53, 47, 108, 1)" : "rgba(53, 47, 108, 0.5)", cursor: "pointer" }} onClick={() => { setApplication({ ...application, isThisWhatsappNumber: "Yes" }) }}>Yes</p>
                            <p>|</p>
                            <p style={{ color: application.isThisWhatsappNumber === "No" ? "rgba(53, 47, 108, 1)" : "rgba(53, 47, 108, 0.5)", cursor: "pointer" }} onClick={() => { setApplication({ ...application, isThisWhatsappNumber: "No" }) }}>No</p>
                        </div>

                        {
                            application.isThisWhatsappNumber === "No"
                            &&
                            <div style={{ width: "100%", gridColumn: "1/2" }}>
                                <p className={Styles.label}>Whatsapp Number (With country code)</p>
                                <input value={application.whatsappNumber} onChange={(event) => { setApplication({ ...application, whatsappNumber: event.target.value.replace(/[^0-9]+/g, "")}) }} className={Styles.inputField} placeholder="" InputProps={{ disableUnderline: true }} variant="standard" />
                            </div>
                        }

                        {/* <div style={{ width: "100%", gridColumn: "1/2" }}>
                            <p className={Styles.label}>Country of Study</p>
                            <input value={application.desiredCountry} onChange={(event) => { setApplication({ ...application, desiredCountry: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>University</p>
                            <input value={application.desiredUniversity} onChange={(event) => { setApplication({ ...application, desiredUniversity: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div> */}

                        <div style={{ width: "100%", gridColumn: "1/2" }}>
                            <p className={Styles.label}>Tentative Travel Date</p>
                            <input type="date" value={application.travelDate} onChange={(event) => { setApplication({ ...application, travelDate: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>

                        <div style={{ width: "100%"}}>
                            <p className={Styles.label}>Coverage Start Date</p>
                            <input type="date" value={application.coverageStartDate} onChange={(event) => { setApplication({ ...application, coverageStartDate: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>

                        <div style={{ width: "100%"}}>
                            <p className={Styles.label}>Coverage End Date</p>
                            <input type="date" value={application.coverageEndDate} onChange={(event) => { setApplication({ ...application, coverageEndDate: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>

                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Current location?</p>
                            <Select style={{ width: "100%" }} value={application.currentCountry} onChange={(event) => { handelInIndia(event.target.value) }} disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                                <MenuItem value={"India"} >India</MenuItem>
                                <MenuItem value={"United States"} >United States</MenuItem>
                                <MenuItem value={"Other"} >Other</MenuItem>
                            </Select>
                        </div>
                    </div>
                }

                {
                    currentTab === "More Details"
                    &&
                    <div className={Styles.formContainer}>

                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>PAN number</p>
                            <input value={application.panNumber} onChange={(event) => { setApplication({ ...application, panNumber: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Sponser name</p>
                            <input value={application.sponsorName} onChange={(event) => { setApplication({ ...application, sponsorName: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Emergency contact number (Indian, With country code)</p>
                            <input value={application.emergencyNumber} onChange={(event) => { setApplication({ ...application, emergencyNumber: event.target.value.replace(/[^0-9]+/g, "") }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Alternate email-id</p>
                            <input value={application.alternateEmail} onChange={(event) => { setApplication({ ...application, alternateEmail: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Mother's name</p>
                            <input value={application.motherName} onChange={(event) => { setApplication({ ...application, motherName: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Passport number</p>
                            <input value={application.passportNumber} onChange={(event) => { setApplication({ ...application, passportNumber: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Nominee Name</p>
                            <input value={application.nomineeName} onChange={(event) => { setApplication({ ...application, nomineeName: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>

                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Nominee relation</p>
                            <input value={application.nomineeRelation} onChange={(event) => { setApplication({ ...application, nomineeRelation: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Nominee DOB</p>
                            <input type="date" value={application.nomineeDOB} onChange={(event) => { setApplication({ ...application, nomineeDOB: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        <div style={{ width: "100%", gridColumn: "1/4" }}>
                            <p className={Styles.label}>Address</p>
                            <input value={application.indiaAddress} onChange={(event) => { setApplication({ ...application, indiaAddress: event.target.value }) }} className={Styles.inputField} placeholder="Full Name" InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>


                        <div className={Styles.yesNoContainer} style={{ gridColumn: "1/2" }}>
                            <p>Do you have a US address?</p>
                            <p style={{ color: application.haveUsAddress === "Yes" ? "rgba(53, 47, 108, 1)" : "rgba(53, 47, 108, 0.5)", cursor: "pointer" }} onClick={() => { setApplication({ ...application, haveUsAddress: "Yes" }) }}>Yes</p>
                            <p>|</p>
                            <p style={{ color: application.haveUsAddress === "No" ? "rgba(53, 47, 108, 1)" : "rgba(53, 47, 108, 0.5)", cursor: "pointer" }} onClick={() => { setApplication({ ...application, haveUsAddress: "No" }) }}>No</p>
                        </div>

                        {
                            application.haveUsAddress === "Yes"
                            &&
                            <div style={{ width: "100%", gridColumn: "1/4" }}>
                                <p className={Styles.label}>US Address</p>
                                <input value={application.usAddress} onChange={(event) => { setApplication({ ...application, usAddress: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                            </div>
                        }

                    </div>
                }

                {
                    currentTab === "Upload App Docs"
                    &&
                    <>
                        <div className={Styles.documentListContainer}>

                            {
                                application.applicantDocs
                                    ?
                                    application.applicantDocs.map((item, index) => {
                                        return (
                                            <div className={Styles.documentItem}>
                                                <p onClick={() => { handleDocumentUpload(item, "Applicant Docs", index, "applicantDocs") }} style={{ color: item.url === "" ? null : "#12b004" }} className={Styles.documentName}>{item.name}</p>
                                                <FaExternalLinkSquareAlt onClick={() => { window.open(item.url, "_blank") }} color={item.url === "" ? "" : "#12b004"} style={{ cursor: "pointer" }} />
                                            </div>
                                        )
                                    })
                                    :
                                    <div style={{
                                        border: '1px solid #ccc',
                                        borderRadius: '8px',
                                        padding: '16px',
                                        margin: '16px 0',
                                        backgroundColor: '#f9f9f9',
                                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                        fontFamily: 'Arial, sans-serif',
                                        color: '#333'
                                    }}>
                                        <h4 style={{ margin: '0 0 8px', fontSize: '18px' }}>Note</h4>
                                        <p style={{ margin: '0', fontSize: '14px' }}>
                                            Please select applicant's current location
                                        </p>
                                    </div>
                            }

                        </div>
                    </>
                }
            </div>

            <div className={Styles.buttonsContainer}>
                {
                    application.visaCategory && application.visaCategory === "F-1 Student on OPT"
                    ?
                    <ForwardToF1Operations submitting={submitting} handleForwardF1={handleForwardF1} application={application} setApplication={setApplication} getProfileStatus={getProfileStatus} />
                    :                
                    <ForwardToOperations submitting={submitting} handleForward={handleForward} application={application} setApplication={setApplication} getProfileStatus={getProfileStatus} />
                }
            </div>

        </div>
    )
}

const ForwardToF1Operations = ({ submitting, handleForwardF1, application, setApplication, getProfileStatus }) => {

    const [open, setOpen] = useState(false);
    const [formInfo, setFormInfo] = useState({
        deductible: "",
        name: "",
        premium: "",
        provider: ""
    })
    const [universities, setUniversities] = useState([])
    const [scList, setScList] = useState([])
    const [progress, setProgress] = useState(true)

    const openModal = _ => {
        const status = getProfileStatus();
        if (!status) {
            toast.warn("Please complete the profile");
            return;
        } else {
            setOpen(true)
        }

    };
    const closeModal = _ => setOpen(false);

    const handleForwardToOP = () => {

        if (!formInfo.name) {
            toast.warn("Please select plan name");
            return
        }

        if (!formInfo.deductible) {
            toast.warn("Please select deductible");
            return
        }

        var start = moment(application.coverageStartDate, "YYYY-MM-DD");
        var end = moment(application.coverageEndDate, "YYYY-MM-DD");
        let coveragePeriod = Math.ceil(moment.duration(end.diff(start)).asDays() + 1)

        const finalApplication = {
            ...application,
            planDetail: { ...formInfo },
            sentToOperationsDate: moment().format("DD-MM-YYYY h:mm a"),
            status: "Operations",
            subStatus: 'New',
            product: "F1-OPT",
            dob: application.dob === "" ? "" : moment(application.dob, "YYYY-MM-DD").format("DD-MM-YYYY"),
            travelDate: application.travelDate === "" ? "" : moment(application.travelDate, "YYYY-MM-DD").format("DD-MM-YYYY"),
            coverageStartDate: application.coverageStartDate === "" ? "" : moment(application.coverageStartDate, "YYYY-MM-DD").format("DD-MM-YYYY"),
            coverageEndDate: application.coverageEndDate === "" ? "" : moment(application.coverageEndDate, "YYYY-MM-DD").format("DD-MM-YYYY"),
            coveragePeriod: coveragePeriod
        }
        handleForwardF1(finalApplication);
    }

    const handleTravelingTo = (value) => {
        setProgress(true)
        let temp = []
        
        setApplication({...application, desiredCountry: value, desiredUniversity:""})
        setFormInfo({name:"", deductible:"", premium:"", provider:""})
        setScList([])
        if (value) {
            onValue(ref(firebaseDatabase, "COUNTRY_WISE_UNIVERSITIES/"+value), (snapShot) => {
                if (snapShot.exists()) {
                    for (const key in snapShot.val()) {
                        temp.push(snapShot.child(key).val())
                    }
                    setUniversities(temp)
                }
            }, {onlyOnce:true})
        } else {
            setUniversities(temp)
        }
    }

    const handleUniversity = (university) => {
        setProgress(true)
        setApplication({...application, desiredUniversity: university})
        setFormInfo({name:"", deductible:"", premium:"", provider:""}) 
        setScList(["SC Special", "SC Prima 200", "SC Prima 300", "SC Prima 500"])
    }

    const scPlan = {
        "22 Years or Under" : {
            "annual": 1093,
            "daily": 2.98633879781421
        },
        "23 - 26 Years" : {
            "annual": 1511,
            "daily": 4.12841530054645
        },
        "27 - 30 Years" : {
            "annual": 3497,
            "daily": 9.55464480874317
        },
        "31+ years" : {
            "annual": 16920,
            "daily": 46.2295081967213
        }
    }

    const handlePlan = (value) => {
        setProgress(true)

        let tempDays = "1-30"
        var start = moment(application.coverageStartDate, "YYYY-MM-DD");
        var end = moment(application.coverageEndDate, "YYYY-MM-DD");
        console.log(moment.duration(end.diff(start)).asDays() + 1)
        let coveragePeriod = Math.ceil(moment.duration(end.diff(start)).asDays() + 1)

        if (coveragePeriod >= 0 && coveragePeriod <= 30) {
            tempDays = "1-30";
        } else if (coveragePeriod >= 31 && coveragePeriod <= 60) {
            tempDays = "31-60";
        } else if (coveragePeriod >= 61 && coveragePeriod <= 90) {
            tempDays = "61-90";
        } else if (coveragePeriod >= 91 && coveragePeriod <= 120) {
            tempDays = "91-120";
        } else if (coveragePeriod >= 121 && coveragePeriod <= 180) {
            tempDays = "121-180";
        } else if (coveragePeriod >= 181 && coveragePeriod <= 240) {
            tempDays = "181-240";
        } else if (coveragePeriod >= 241 && coveragePeriod <= 270) {
            tempDays = "241-270";
        } else if (coveragePeriod >= 271) {
            tempDays = "271-365";
        }

        switch (value) {
            case "SC Special": {
                var age = Math.ceil(moment().diff(application.dob, 'years'));
                let ageWindow = ""
                if (age <= 22) {
                    ageWindow = "22 Years or Under"
                } else if (age >= 23 && age <= 26) {
                    ageWindow = "23 - 26 Years"
                } else if (age >= 27 && age <= 30) {
                    ageWindow = "27 - 30 Years"
                } else {
                    ageWindow = "31+ years"
                }

                
                let tempPremium = ""
                if (coveragePeriod === 365) {
                    tempPremium = scPlan[ageWindow]["annual"]
                } else {
                    tempPremium = Math.round(scPlan[ageWindow]["daily"] * coveragePeriod)
                }
                setFormInfo({
                    ...formInfo,
                    name: value,
                    premium: tempPremium,
                    provider: "Bajaj",
                    deductible: "a"
                })
                setProgress(false)
                break
            }
            case "SC Prima 200": {
                setFormInfo({
                    ...formInfo,
                    name: value,
                    premium: HealthPremiumMap["SC Prima 200"]["US or Canada"][tempDays],
                    provider: "Bajaj",
                    deductible: "a"
                })
                setProgress(false)
                break
            }
            case "SC Prima 300": {
                setFormInfo({
                    ...formInfo,
                    name: value,
                    premium: HealthPremiumMap["SC Prima 300"]["US or Canada"][tempDays],
                    provider: "Bajaj",
                    deductible: "a"
                })
                setProgress(false)
                break
            }
            case "SC Prima 500": {
                setFormInfo({
                    ...formInfo,
                    name: value,
                    premium: HealthPremiumMap["SC Prima 500"]["US or Canada"][tempDays],
                    provider: "Bajaj",
                    deductible: "a"
                })
                setProgress(false)
                break
            }
            default: {}
        } 
    }

    return (
        <>
            <Button disabled={submitting} onClick={openModal} sx={{ marginTop: '40px' }} variant='contained' className={Styles.button}>Send to HI-Op</Button>
            <Modal open={open} onClose={closeModal}>
                <div className={Styles.alertModalContainer}>
                    <div className={Styles.alertModalContent}>
                        <div>
                            <h2 className={Styles.alertModalTitle}>Please provide F1 plan details</h2>
                        </div>
                        <Divider />
                        <div className={Styles.alertFormContainer}>
                            <div style={{ width: "100%", display:"flex", flexDirection:"column", alignItems:"flex-start", marginTop:20, flex:1 }}>
                                <p className={Styles.label}>Desired Country</p>
                                <CustomAutoComplete
                                    className={Styles.inputField}
                                    label="Select Traveling to"
                                    variant="standard"
                                    value={application.desiredCountry}
                                    InputProps={{ disableUnderline: true }}
                                    onChange={(event, value) => {handleTravelingTo(value)}}
                                    options={["Australia", "Canada", "Germany", "Ireland", "Singapore", "UAE", "United Kingdom", "United States", "Others"]}
                                /> 
                            </div>

                            <div style={{ width: "100%", display:"flex", flexDirection:"column", alignItems:"flex-start", marginTop:20, flex:1 }}>
                                <p className={Styles.label}>Desired University</p>
                                <CustomAutoComplete
                                    className={Styles.inputField}
                                    label="Select Universities"
                                    variant="standard"
                                    value={application.desiredUniversity}
                                    InputProps={{ disableUnderline: true }}
                                    onChange={(event, value) => {handleUniversity(value)}}
                                    options={universities}
                                /> 
                            </div>
                            {
                                scList.length > 0
                                &&
                                <div style={{ width: "100%" }}>
                                    <p className={Styles.label}>Select Plan</p>
                                    <Select style={{ width: "100%" }} value={formInfo.name} onChange={(event) => {handlePlan(event.target.value)}} disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                                        {
                                            scList.map((item) => {return(
                                                <MenuItem value={item}>{item}</MenuItem>
                                            )})
                                        }
                                    </Select>
                                </div>
                            }
                        </div>
                        <p style={{width:"100%", textAlign:"center", fontSize:16, fontWeight:600, paddingTop:20}}>Premium: {formInfo.premium}</p>
                        <Button disabled={submitting || progress} onClick={handleForwardToOP} sx={{ margin: '24px auto' }} variant='contained' className={Styles.button}>Forward</Button>
                    </div>
                </div>
            </Modal>
        </>
    )
}

const ForwardToOperations = ({ submitting, handleForward, application, setApplication, getProfileStatus }) => {
    const [open, setOpen] = useState(false);
    const [formInfo, setFormInfo] = useState({
        deductible: "",
        name: "",
        premium: "",
        provider: ""
    })
    const [universities, setUniversities] = useState([])
    const [scList, setScList] = useState([])
    const [progress, setProgress] = useState(true)
    const planList = ["SC Gold Plus", "SC Prima 100", "SC Prima 200", "SC Prima 300", "SC Prima 500", "SC Prima 500 Plus", "SC Prima 750"]

    const openModal = _ => {
        const status = getProfileStatus();
        if (!status) {
            toast.warn("Please complete the profile");
            return;
        } else {
            setOpen(true)
        }

    };
    const closeModal = _ => setOpen(false);

    const handleForwardToOP = () => {

        if (!formInfo.name) {
            toast.warn("Please select plan name");
            return
        }

        if (!formInfo.deductible) {
            toast.warn("Please select deductible");
            return
        }

        const finalApplication = {
            ...application,
            planDetail: { ...formInfo },
            sentToOperationsDate: moment().format("DD-MM-YYYY h:mm a"),
            status: "Operations",
            subStatus: 'New',
            dob: application.dob === "" ? "" : moment(application.dob, "YYYY-MM-DD").format("DD-MM-YYYY"),
            travelDate: application.travelDate === "" ? "" : moment(application.travelDate, "YYYY-MM-DD").format("DD-MM-YYYY"),
            coverageStartDate: application.coverageStartDate === "" ? "" : moment(application.coverageStartDate, "YYYY-MM-DD").format("DD-MM-YYYY"),
            coverageEndDate: application.coverageEndDate === "" ? "" : moment(application.coverageEndDate, "YYYY-MM-DD").format("DD-MM-YYYY")
        }
        handleForward(finalApplication);
    }

    const handleTravelingTo = (value) => {
        setProgress(true)
        let temp = []
        
        setApplication({...application, desiredCountry: value, desiredUniversity:""})
        setFormInfo({name:"", deductible:"", premium:"", provider:""})
        setScList([])
        if (value) {
            onValue(ref(firebaseDatabase, "COUNTRY_WISE_UNIVERSITIES/"+value), (snapShot) => {
                if (snapShot.exists()) {
                    for (const key in snapShot.val()) {
                        temp.push(snapShot.child(key).val())
                    }
                    setUniversities(temp)
                }
            }, {onlyOnce:true})
        } else {
            setUniversities(temp)
        }
    }

    const handleUniversity = (university) => {
        setProgress(true)
        let temp = []
        setApplication({...application, desiredUniversity: university})
        setFormInfo({name:"", deductible:"", premium:"", provider:""})
        if (application.desiredCountry !== "United States") {
            setScList(["SC Prima 100", "SC Prima 200", "SC Prima 300", "SC Prima 500"])
            return
        }
        if (university) {
            onValue(ref(firebaseDatabaseSC, `HEALTH_PLANS/${university}/plans`), (snapShot) => {
                if (snapShot.exists()) {
                    for (const key in snapShot.val()) {
                        if (planList.includes(snapShot.child(key).val())) {
                            temp.push(snapShot.child(key).val()) 
                        }
                    }
                    setScList(temp)
                } else {
                    setScList(temp)
                }
            }, {onlyOnce:true})
        } else {
            setScList(temp)
        }
    }

    const handlePlan = (type, value) => {
        setProgress(true)
        if (type === "Name") {
            switch (value) {
                case "SC Prima 100": {
                    setFormInfo({
                        ...formInfo,
                        name: value,
                        premium: (application.desiredCountry==="United States"||application.desiredCountry==="Canada")?"12549":"6974",
                        provider: "Bajaj",
                        deductible: "a"
                    })
                    setProgress(false)
                    break
                }
                case "SC Prima 200": {
                    setFormInfo({
                        ...formInfo,
                        name: value,
                        premium: (application.desiredCountry==="United States"||application.desiredCountry==="Canada")?"17960":"9964",
                        provider: "Bajaj",
                        deductible: "a"
                    })
                    setProgress(false)
                    break
                }
                case "SC Prima 300": {
                    let premiumTemp = (application.desiredCountry==="United States"||application.desiredCountry==="Canada")?22845:12675
                    if (application.desiredUniversity === "Pace University" || application.desiredUniversity === "Case Western Reserve University" || application.desiredUniversity === "University of Illinois at Springfield") {
                        premiumTemp += 10281
                    }
                    setFormInfo({
                        ...formInfo,
                        name: value,
                        premium: premiumTemp.toString(),
                        provider: "Bajaj",
                        deductible: "a"
                    })
                    setProgress(false)
                    break
                }
                case "SC Prima 500": {
                    let premiumTemp = (application.desiredCountry==="United States"||application.desiredCountry==="Canada")?26653:14787

                    if (application.desiredUniversity === "Drexel University") {
                        premiumTemp += 17323
                    }
                    if (application.desiredUniversity === "Virginia Tech") {
                        premiumTemp = 49370
                    }
                    if (application.desiredUniversity === "2Fly Airborne") {
                        premiumTemp = 49370
                    }
                    if (application.desiredUniversity === "Stanford University") {
                        premiumTemp = 49370
                    }
                    if (application.desiredUniversity === "Pace University" || application.desiredUniversity === "Case Western Reserve University") {
                        premiumTemp += 11992
                    }
                    if (application.desiredUniversity === "University Of Colorado Boulder" ) {
                        premiumTemp = 43976
                    }

                    setFormInfo({
                        ...formInfo,
                        name: value,
                        premium: premiumTemp.toString(),
                        provider: "Bajaj",
                        deductible: "a"
                    })
                    setProgress(false)
                    break
                }
                case "SC Prima 500 Plus": {
                    let premiumTemp = 26653
                    if (application.desiredUniversity === "University Of Colorado Boulder" || application.desiredUniversity === "University of Illinois at Springfield") {
                        premiumTemp = 49370
                    }

                    setFormInfo({
                        ...formInfo,
                        name: value,
                        premium: premiumTemp.toString(),
                        provider: "Bajaj",
                        deductible: "a"
                    })
                    setProgress(false)
                    break
                }
                case "SC Prima 750": {
                    let premiumTemp = 45429
                    if (application.desiredUniversity === "Pace University") {
                        premiumTemp = 45429
                    }
                    setFormInfo({
                        ...formInfo,
                        name: value,
                        premium: premiumTemp.toString(),
                        provider: "Bajaj",
                        deductible: "a"
                    })
                    setProgress(false)
                    break
                }
                case "SC Gold Plus": {
                    setFormInfo({
                        ...formInfo,
                        name: value,
                        premium: "",
                        provider: "ICICI",
                        deductible: ""
                    })
                    break
                }
                default: {}
            }
        } else if (type === "Deductible") {
            switch (value) {
                case "100,000": {
                    setFormInfo({
                        ...formInfo,
                        name: "SC Gold Plus",
                        premium: "29064",
                        provider: "ICICI",
                        deductible: value
                    })
                    break
                }
                case "250,000": {
                    setFormInfo({
                        ...formInfo,
                        name: "SC Gold Plus",
                        premium: "45345",
                        provider: "ICICI",
                        deductible: value
                    })
                    break
                }
                case "500,000": {
                    setFormInfo({
                        ...formInfo,
                        name: "SC Gold Plus",
                        premium: "68884",
                        provider: "ICICI",
                        deductible: value
                    })
                    break
                }
                default: {}
            }
            setProgress(false)
        }
    }

    return (
        <>
            <Button disabled={submitting} onClick={openModal} sx={{ marginTop: '40px' }} variant='contained' className={Styles.button}>Send to HI-Op</Button>
            <Modal open={open} onClose={closeModal}>
                <div className={Styles.alertModalContainer}>
                    <div className={Styles.alertModalContent}>
                        <div>
                            <h2 className={Styles.alertModalTitle}>Please provide plan details</h2>
                        </div>
                        <Divider />
                        <div className={Styles.alertFormContainer}>
                            <div style={{ width: "100%", display:"flex", flexDirection:"column", alignItems:"flex-start", marginTop:20, flex:1 }}>
                                <p className={Styles.label}>Desired Country</p>
                                <CustomAutoComplete
                                    className={Styles.inputField}
                                    label="Select Traveling to"
                                    variant="standard"
                                    value={application.desiredCountry}
                                    InputProps={{ disableUnderline: true }}
                                    onChange={(event, value) => {handleTravelingTo(value)}}
                                    options={["Australia", "Canada", "Germany", "Ireland", "Singapore", "UAE", "United Kingdom", "United States", "Others"]}
                                /> 
                            </div>

                            <div style={{ width: "100%", display:"flex", flexDirection:"column", alignItems:"flex-start", marginTop:20, flex:1 }}>
                                <p className={Styles.label}>Desired University</p>
                                <CustomAutoComplete
                                    className={Styles.inputField}
                                    label="Select Universities"
                                    variant="standard"
                                    value={application.desiredUniversity}
                                    InputProps={{ disableUnderline: true }}
                                    onChange={(event, value) => {handleUniversity(value)}}
                                    options={universities}
                                /> 
                            </div>
                            {
                                scList.length > 0
                                &&
                                <div style={{ width: "100%" }}>
                                    <p className={Styles.label}>Select Plan</p>
                                    <Select style={{ width: "100%" }} value={formInfo.name} onChange={(event) => {handlePlan("Name", event.target.value)}} disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                                        {
                                            scList.map((item) => {return(
                                                <MenuItem value={item}>{item}</MenuItem>
                                            )})
                                        }
                                    </Select>
                                </div>
                            }
                            {
                                formInfo.name === "SC Gold Plus"
                                &&
                                <div style={{ width: "100%" }}>
                                    <p className={Styles.label} >Select Sum Insured</p>
                                    <Select style={{ width: "100%" }} value={formInfo.deductible} onChange={(event) => {handlePlan("Deductible", event.target.value)}} disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                                        <MenuItem value={"100,000"} >100,000</MenuItem>
                                        <MenuItem value={"250,000"} >250,000</MenuItem>
                                        <MenuItem value={"500,000"} >500,000</MenuItem>
                                    </Select>
                                </div>
                            }
                        </div>
                        <p style={{width:"100%", textAlign:"center", fontSize:16, fontWeight:600, paddingTop:20}}>Premium: {formInfo.premium}</p>
                        <Button disabled={submitting || progress} onClick={handleForwardToOP} sx={{ margin: '24px auto' }} variant='contained' className={Styles.button}>Forward</Button>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default HiOperationsCreateFile