import React, { useContext } from "react";
import Styles from "./HealthInsurance.module.css"
import { useLocation, useNavigate } from "react-router-dom";
import UserContext from "../../context/UserData.context";
import { firebaseAuth, firebaseDatabase } from "../../backend/firebaseHandler";
import { ref, set } from "firebase/database";
import { AiFillHome, AiOutlinePoweroff } from "react-icons/ai";
import { HiUserAdd } from 'react-icons/hi';
import whiteLogo from "../../assets/white_logo_sc.svg"
import { Divider } from "@mui/material";
import HiAdvisorNewLeads from "../../components/HealthInsuranceTabs/HiAdvisorNewLeads/HiAdvisorNewLeads.component";
import HiAdvisorNewLeadDetail from "../../components/HiAdvisorNewLeadDetail/HiAdvisorNewLeadDetail.component";
import HiAdvisorRescheduledLeads from "../../components/HealthInsuranceTabs/HiAdvisorRescheduledLeads/HiAdvisorRescheduledLeads.component";
import HiAdvisorInterestedLeads from "../../components/HealthInsuranceTabs/HiAdvisorInterestedLeads/HiAdvisorInterestedLeads.component";
import HiAdvisorRescheduledLeadDetail from "../../components/HiAdvisorRescheduledLeadDetail/HiAdvisorRescheduledLeadDetail.component";
import HiAdvisorCompleteLeadDetail from "../../components/HiAdvisorCompleteLeadDetail/HiAdvisorCompleteLeadDetail.component";
import HiAdvisorOperationsLeads from "../../components/HealthInsuranceTabs/HiAdvisorOperationsLeads/HiAdvisorOperationsLeads.component";
import HiOperationsNewLeads from "../../components/HealthInsuranceTabs/HiOperationsNewLeads/HiOperationsNewLeads.component";
import HiOperationsCompleteDetail from "../../components/HiOperationsCompleteDetail/HiOperationsCompleteDetail.component";
import HiAdvisorClosedLeads from "../../components/HealthInsuranceTabs/HiAdvisorClosedLeads/HiAdvisorClosedLeads.component";
import HiAdvisorClosedLeadDetail from "../../components/HiAdvisorClosedLeadDetail/HiAdvisorClosedLeadDetail.component";
import HiManagerLeadStatus from "../../components/HealthInsuranceTabs/HiManagerLeadStatus/HiManagerLeadStatus.component";
import LatestVersion from "../../workload/LatestVersion";
import HiOperationLinkSentLeads from "../../components/HealthInsuranceTabs/HiOperationLinkSentLeads/HiOperationLinkSentLeads.component";
import HiOperationPolicyGeneratedLeads from "../../components/HealthInsuranceTabs/HiOperationPolicyGeneratedLeads/HiOperationPolicyGeneratedLeads.component";
import DistributeLeads from "../../components/HomePageTabs/DistributeLeads/DistributeLeads.component";
import HiReassignLeads from "../../components/HealthInsuranceTabs/HiReassignLeads/HiReassignLeads.component";
import AddSingleLead from "../../components/HomePageTabs/AddSingleLead/AddSingleLead.component";
import NumberRecords from "../../components/HealthInsuranceTabs/NumberRecords/NumberRecords.component";
import HiOperationsCreateFile from "../../components/HealthInsuranceTabs/HiOperationsCreateFile/HiOperationsCreateFile.component";
import HiAdminCompleteLeadDetail from "../../components/HiAdminCompleteLeadDetail/HiAdminCompleteLeadDetail.component";
import SbpPurchaseRecords from "../../components/HealthInsuranceTabs/SbpPurchaseRecords/SbpPurchaseRecords.component";
import SbpPurchaseDetail from "../../components/HiSbpPurchaseDetail/HiSbpPurchaseDetail.component";
import F1OptPurchaseRecords from "../../components/HealthInsuranceTabs/F1OptPurchaseRecords/F1OptPurchaseRecords.component";
import F1OptPurchaseDetail from "../../components/F1OptPurchaseDetail/F1OptPurchaseDetail.component";
import HiFreeTiClaims from "../../components/HealthInsuranceTabs/HiFreeTiClaims/HiFreeTiClaims.component";
import TiClaimDetail from "../../components/HealthInsuranceTabs/TiClaimDetail/TiClaimDetail.component";
import HiRenewalRequests from "../../components/HealthInsuranceTabs/HiRenewalRequests/HiRenewalRequests.component";
import RenewalRequestDetail from "../../components/RenewalRequestDetail/RenewalRequestDetail.component";

const HealthInsurance = () => {

    const navigate = useNavigate()
    const location = useLocation().pathname
    const [userData, setUserData] = useContext(UserContext)

    const handleActiveTab = (tab) => {
        navigate(`/${tab}`)
    }

    window.addEventListener("unload", async (event) => {
        await set(ref(firebaseDatabase, `USER_ARCHIVE/${userData.uid}/currently`), "Offline")
    })

    const handleLogout = async () => {

        await set(ref(firebaseDatabase, `USER_ARCHIVE/${userData.uid}/currently`), "Offline")
        firebaseAuth.signOut()
        sessionStorage.clear();
    }

    return (
        <div className={Styles.homePageContainer}>
            <div className={Styles.homepageSideBarContainer}>
                <img src={whiteLogo} alt="student-cover" className={Styles.dp} />
                <p className={Styles.userName}>{userData.name}</p>
                <Divider style={{ color: 'white', marginTop: 40, backgroundColor: 'white' }} />
                
                <div onClick={_ => handleActiveTab("health-insurance")} role='button' className={location === "/health-insurance" ? Styles.activeSideBarButton : Styles.inActiveSideBarButton}>
                    <AiFillHome size={20} color={location === "/health-insurance" ? "#352F6C" : "#fff"} />
                    <p className={Styles.sideBarButtonText} style={{ color: location === "/health-insurance" ? "#352F6C" : "#fff" }}>Dashboard</p>
                </div>

                {
                    userData.position === "Manager"
                    ?
                    <>
                        <div onClick={_ => handleActiveTab("health-insurance/distribute-leads")} role='button' className={location.startsWith("/health-insurance/distribute-leads") ? Styles.activeSideBarButton : Styles.inActiveSideBarButton}>
                            <AiFillHome size={20} color={location.startsWith("/health-insurance/distribute-leads") ? "#352F6C" : "#fff"} />
                            <p className={Styles.sideBarButtonText} style={{ color: location.startsWith("/health-insurance/distribute-leads") ? "#352F6C" : "#fff" }}>Distribute Leads</p>
                        </div>
                        <div onClick={_ => handleActiveTab("health-insurance/lead-status")} role='button' className={location.startsWith("/health-insurance/lead-status") ? Styles.activeSideBarButton : Styles.inActiveSideBarButton}>
                            <AiFillHome size={20} color={location.startsWith("/health-insurance/lead-status") ? "#352F6C" : "#fff"} />
                            <p className={Styles.sideBarButtonText} style={{ color: location.startsWith("/health-insurance/lead-status") ? "#352F6C" : "#fff" }}>Lead Status</p>
                        </div>
                        <div onClick={_ => handleActiveTab("health-insurance/re-assign-leads")} role='button' className={location.startsWith("/health-insurance/re-assign-leads") ? Styles.activeSideBarButton : Styles.inActiveSideBarButton}>
                            <AiFillHome size={20} color={location.startsWith("/health-insurance/re-assign-leads") ? "#352F6C" : "#fff"} />
                            <p className={Styles.sideBarButtonText} style={{ color: location.startsWith("/health-insurance/re-assign-leads") ? "#352F6C" : "#fff" }}>Re-assign Leads</p>
                        </div>
                        <div onClick={_ => handleActiveTab("health-insurance/number-record")} role='button' className={location.startsWith("/health-insurance/number-record") ? Styles.activeSideBarButton : Styles.inActiveSideBarButton}>
                            <AiFillHome size={20} color={location.startsWith("/health-insurance/number-record") ? "#352F6C" : "#fff"} />
                            <p className={Styles.sideBarButtonText} style={{ color: location.startsWith("/health-insurance/number-record") ? "#352F6C" : "#fff" }}>Number Record</p>
                        </div>
                        <div onClick={_ => handleActiveTab("health-insurance/sbp-purchase-record")} role='button' className={location.startsWith("/health-insurance/sbp-purchase-record") ? Styles.activeSideBarButton : Styles.inActiveSideBarButton}>
                            <AiFillHome size={20} color={location.startsWith("/health-insurance/sbp-purchase-record") ? "#352F6C" : "#fff"} />
                            <p className={Styles.sideBarButtonText} style={{ color: location.startsWith("/health-insurance/sbp-purchase-record") ? "#352F6C" : "#fff" }}>Student Benefit Plans</p>
                        </div>
                        <div onClick={_ => handleActiveTab("health-insurance/f1-opt-purchase-record")} role='button' className={location.startsWith("/health-insurance/f1-opt-purchase-record") ? Styles.activeSideBarButton : Styles.inActiveSideBarButton}>
                            <AiFillHome size={20} color={location.startsWith("/health-insurance/f1-opt-purchase-record") ? "#352F6C" : "#fff"} />
                            <p className={Styles.sideBarButtonText} style={{ color: location.startsWith("/health-insurance/f1-opt-purchase-record") ? "#352F6C" : "#fff" }}>F1-OPT Purchases</p>
                        </div>
                    </>
                    :
                    null
                }

                {
                    (userData.position === "Advisor" || userData.position === "Manager")
                    ?
                    <>
                        <div onClick={_ => handleActiveTab("health-insurance/new-leads")} role='button' className={location.startsWith("/health-insurance/new-leads") ? Styles.activeSideBarButton : Styles.inActiveSideBarButton}>
                            <AiFillHome size={20} color={location.startsWith("/health-insurance/new-leads") ? "#352F6C" : "#fff"} />
                            <p className={Styles.sideBarButtonText} style={{ color: location.startsWith("/health-insurance/new-leads") ? "#352F6C" : "#fff" }}>New Leads</p>
                        </div>

                        <div onClick={_ => handleActiveTab("health-insurance/rescheduled-leads")} role='button' className={location.startsWith("/health-insurance/rescheduled-leads") ? Styles.activeSideBarButton : Styles.inActiveSideBarButton}>
                            <AiFillHome size={20} color={location.startsWith("/health-insurance/rescheduled-leads") ? "#352F6C" : "#fff"} />
                            <p className={Styles.sideBarButtonText} style={{ color: location.startsWith("/health-insurance/rescheduled-leads") ? "#352F6C" : "#fff" }}>Rescheduled Leads</p>
                        </div>

                        <div onClick={_ => handleActiveTab("health-insurance/interested-leads")} role='button' className={location.startsWith("/health-insurance/interested-leads") ? Styles.activeSideBarButton : Styles.inActiveSideBarButton}>
                            <AiFillHome size={20} color={location.startsWith("/health-insurance/interested-leads") ? "#352F6C" : "#fff"} />
                            <p className={Styles.sideBarButtonText} style={{ color: location.startsWith("/health-insurance/interested-leads") ? "#352F6C" : "#fff" }}>Interested Leads</p>
                        </div>

                        <div onClick={_ => handleActiveTab("health-insurance/operations-leads")} role='button' className={location.startsWith("/health-insurance/operations-leads") ? Styles.activeSideBarButton : Styles.inActiveSideBarButton}>
                            <AiFillHome size={20} color={location.startsWith("/health-insurance/operations-leads") ? "#352F6C" : "#fff"} />
                            <p className={Styles.sideBarButtonText} style={{ color: location.startsWith("/health-insurance/operations-leads") ? "#352F6C" : "#fff" }}>Operations Leads</p>
                        </div>

                        <div onClick={_ => handleActiveTab("health-insurance/closed-leads")} role='button' className={location.startsWith("/health-insurance/closed-leads") ? Styles.activeSideBarButton : Styles.inActiveSideBarButton}>
                            <AiFillHome size={20} color={location.startsWith("/health-insurance/closed-leads") ? "#352F6C" : "#fff"} />
                            <p className={Styles.sideBarButtonText} style={{ color: location.startsWith("/health-insurance/closed-leads") ? "#352F6C" : "#fff" }}>Closed Leads</p>
                        </div>
                    </>
                    :
                    null
                } 
                
                {
                    userData.position === "Operations"
                    ?
                    <>
                        {
                            userData.rights.includes("CreateFiles")
                            &&
                            <div onClick={_ => handleActiveTab("health-insurance/create-hi-files")} role='button' className={location.startsWith("/health-insurance/create-hi-files") ? Styles.activeSideBarButton : Styles.inActiveSideBarButton}>
                                <AiFillHome size={20} color={location.startsWith("/health-insurance/create-hi-files") ? "#352F6C" : "#fff"} />
                                <p className={Styles.sideBarButtonText} style={{ color: location.startsWith("/health-insurance/create-hi-files") ? "#352F6C" : "#fff" }}>Create Files</p>
                            </div>
                        }
                        <div onClick={_ => handleActiveTab("health-insurance/operations-new-leads")} role='button' className={location.startsWith("/health-insurance/operations-new-leads") ? Styles.activeSideBarButton : Styles.inActiveSideBarButton}>
                            <AiFillHome size={20} color={location.startsWith("/health-insurance/operations-new-leads") ? "#352F6C" : "#fff"} />
                            <p className={Styles.sideBarButtonText} style={{ color: location.startsWith("/health-insurance/operations-new-leads") ? "#352F6C" : "#fff" }}>New Leads</p>
                        </div>

                        <div onClick={_ => handleActiveTab("health-insurance/operations-link-sent")} role='button' className={location.startsWith("/health-insurance/operations-link-sent") ? Styles.activeSideBarButton : Styles.inActiveSideBarButton}>
                            <AiFillHome size={20} color={location.startsWith("/health-insurance/operations-link-sent") ? "#352F6C" : "#fff"} />
                            <p className={Styles.sideBarButtonText} style={{ color: location.startsWith("/health-insurance/operations-link-sent") ? "#352F6C" : "#fff" }}>Link Sent</p>
                        </div>

                        <div onClick={_ => handleActiveTab("health-insurance/operations-policy-generated")} role='button' className={location.startsWith("/health-insurance/operations-policy-generated") ? Styles.activeSideBarButton : Styles.inActiveSideBarButton}>
                            <AiFillHome size={20} color={location.startsWith("/health-insurance/operations-policy-generated") ? "#352F6C" : "#fff"} />
                            <p className={Styles.sideBarButtonText} style={{ color: location.startsWith("/health-insurance/operations-policy-generated") ? "#352F6C" : "#fff" }}>Policy Generated</p>
                        </div>

                        <div onClick={_ => handleActiveTab("health-insurance/hi-renewal-record")} role='button' className={location.startsWith("/health-insurance/hi-renewal-record") ? Styles.activeSideBarButton : Styles.inActiveSideBarButton}>
                            <AiFillHome size={20} color={location.startsWith("/health-insurance/hi-renewal-record") ? "#352F6C" : "#fff"} />
                            <p className={Styles.sideBarButtonText} style={{ color: location.startsWith("/health-insurance/hi-renewal-record") ? "#352F6C" : "#fff" }}>HI Renewals</p>
                        </div>

                        <div onClick={_ => handleActiveTab("health-insurance/sbp-purchase-record")} role='button' className={location.startsWith("/health-insurance/sbp-purchase-record") ? Styles.activeSideBarButton : Styles.inActiveSideBarButton}>
                            <AiFillHome size={20} color={location.startsWith("/health-insurance/sbp-purchase-record") ? "#352F6C" : "#fff"} />
                            <p className={Styles.sideBarButtonText} style={{ color: location.startsWith("/health-insurance/sbp-purchase-record") ? "#352F6C" : "#fff" }}>Student Benefit Plans</p>
                        </div>
                        <div onClick={_ => handleActiveTab("health-insurance/f1-opt-purchase-record")} role='button' className={location.startsWith("/health-insurance/f1-opt-purchase-record") ? Styles.activeSideBarButton : Styles.inActiveSideBarButton}>
                            <AiFillHome size={20} color={location.startsWith("/health-insurance/f1-opt-purchase-record") ? "#352F6C" : "#fff"} />
                            <p className={Styles.sideBarButtonText} style={{ color: location.startsWith("/health-insurance/f1-opt-purchase-record") ? "#352F6C" : "#fff" }}>F1-OPT Purchases</p>
                        </div>
                        {
                            userData.uid === "93nbcASsRuV8g8pLF4mga0wxbHM2"
                            &&
                            <div onClick={_ => handleActiveTab("health-insurance/free-ti-claims")} role='button' className={location.startsWith("/health-insurance/free-ti-claims") ? Styles.activeSideBarButton : Styles.inActiveSideBarButton}>
                                <AiFillHome size={20} color={location.startsWith("/health-insurance/free-ti-claims") ? "#352F6C" : "#fff"} />
                                <p className={Styles.sideBarButtonText} style={{ color: location.startsWith("/health-insurance/free-ti-claims") ? "#352F6C" : "#fff" }}>Free TI Claims</p>
                            </div>
                        }
                    </>
                    :
                    null
                }

                <div onClick={_ => handleActiveTab("health-insurance/add-single-lead")} role='button' className={location.startsWith("/health-insurance/add-single-lead") ? Styles.activeSideBarButton : Styles.inActiveSideBarButton}>
                    <AiFillHome size={20} color={location.startsWith("/health-insurance/add-single-lead") ? "#352F6C" : "#fff"} />
                    <p className={Styles.sideBarButtonText} style={{ color: location.startsWith("/health-insurance/add-single-lead") ? "#352F6C" : "#fff" }}>Add Lead</p>
                </div>

                <div className={Styles.inActiveSideBarButton}>
                    <AiOutlinePoweroff size={20} />
                    <p className={Styles.sideBarButtonText} onClick={() => { handleLogout() }}>Sign Out</p>
                </div>

                <div style={{ marginTop: 'auto', color: 'white', padding: 12, textAlign: 'center' }}>
                    <p>{LatestVersion}</p>
                </div>

            </div>
            <div className={Styles.homePageTabContentContainer}>
                {
                    (location === "/health-insurance/distribute-leads" && userData.position === "Manager")
                    &&
                    <DistributeLeads />
                }
                {
                    (location === "/health-insurance/create-hi-files" && userData.position === "Operations")
                    &&
                    <HiOperationsCreateFile />
                }
                {
                    (location === "/health-insurance/number-record" && userData.position === "Manager")
                    &&
                    <NumberRecords />
                }
                {
                    (location === "/health-insurance/lead-status" && userData.position === "Manager")
                    &&
                    <HiManagerLeadStatus />
                }
                {
                    (location === "/health-insurance/lead-status/complete-lead-detail" && userData.position === "Manager")
                    &&
                    <HiAdminCompleteLeadDetail />
                }
                {
                    (location === "/health-insurance/re-assign-leads" && userData.position === "Manager")
                    &&
                    <HiReassignLeads />
                }
                {
                    location === "/health-insurance/new-leads"
                    &&
                    <HiAdvisorNewLeads />
                }
                {
                    location === "/health-insurance/new-leads/new-lead-detail"
                    &&
                    <HiAdvisorNewLeadDetail />
                }
                {
                    location === "/health-insurance/rescheduled-leads"
                    &&
                    <HiAdvisorRescheduledLeads />
                }
                {
                    location === "/health-insurance/rescheduled-leads/rescheduled-lead-detail"
                    &&
                    <HiAdvisorRescheduledLeadDetail />
                }
                {
                    location === "/health-insurance/interested-leads"
                    &&
                    <HiAdvisorInterestedLeads />
                }
                {
                    location === "/health-insurance/interested-leads/interested-lead-detail"
                    &&
                    <HiAdvisorCompleteLeadDetail />
                }
                {
                    location === "/health-insurance/operations-leads"
                    &&
                    <HiAdvisorOperationsLeads />
                }
                {
                    location === "/health-insurance/closed-leads"
                    &&
                    <HiAdvisorClosedLeads />
                }
                {
                    location === "/health-insurance/closed-leads/closed-lead-detail"
                    &&
                    <HiAdvisorClosedLeadDetail />
                }

                {
                    (location === "/health-insurance/operations-new-leads" && userData.position === "Operations")
                    &&
                    <HiOperationsNewLeads />
                }
                {
                    (location === "/health-insurance/operations-new-leads/operations-new-lead-detail" && userData.position === "Operations")
                    &&
                    <HiOperationsCompleteDetail />
                }
                {
                    (location === "/health-insurance/operations-link-sent" && userData.position === "Operations")
                    &&
                    <HiOperationLinkSentLeads />
                }
                {
                    (location === "/health-insurance/operations-policy-generated" && userData.position === "Operations")
                    &&
                    <HiOperationPolicyGeneratedLeads />
                }

                {
                    location === "/health-insurance/add-single-lead"
                    &&
                    <AddSingleLead />
                }

                {
                    (location === "/health-insurance/hi-renewal-record" && userData.position === "Operations")
                    &&
                    <HiRenewalRequests />
                }

                {
                    (location === "/health-insurance/hi-renewal-record/hi-renewal-request-detail" && userData.position === "Operations")
                    &&
                    <RenewalRequestDetail />
                }

                {
                    (location === "/health-insurance/sbp-purchase-record" && userData.position === "Operations")
                    &&
                    <SbpPurchaseRecords />
                }

                {
                    (location === "/health-insurance/sbp-purchase-record/sbp-purchase-detail" && userData.position === "Operations")
                    &&
                    <SbpPurchaseDetail />
                }

                {
                    (location === "/health-insurance/f1-opt-purchase-record" && userData.position === "Operations")
                    &&
                    <F1OptPurchaseRecords />
                }
                
                {
                    (location === "/health-insurance/f1-opt-purchase-record/f1-opt-purchase-detail" && userData.position === "Operations")
                    &&
                    <F1OptPurchaseDetail />
                }

                {
                    location === "/health-insurance/free-ti-claims" && userData.uid === "93nbcASsRuV8g8pLF4mga0wxbHM2"
                    &&
                    <HiFreeTiClaims />
                }
                
                {
                    location === "/health-insurance/free-ti-claims/ti-claim-detail" && userData.uid === "93nbcASsRuV8g8pLF4mga0wxbHM2"
                    &&
                    <TiClaimDetail />
                }
            </div>
        </div>
    )
}

export default HealthInsurance


// Fresh
// 1) Add in HI Applications
// 2) Add in Lead Status
// 3) Add in Operation_direct_leads

// New
// 1) Update HI Applications
// 2) Update Lead Status
// 3) Add in Operation_direct_leads
// 4) Remove from Advisor_new_leads

// Rescheduled
// 1) Update HI Applications
// 2) Update Lead Status
// 3) Add in Operation_direct_leads
// 4) Remove from Advisor_rescheduled_leads

// Interested
// 1) Update HI Applications
// 2) Update Lead Status
// 3) Add in Operation_advisor_leads
// 4) Remove from Advisor_interested_leads
// 5) Add in Advisor_with_operations_leads

// Closed
// 1) Update HI Applications
// 2) Update Lead Status
// 3) Add in Operation_diect_leads
// 4) Remove from Advisor_closed_leads