import React, { useEffect, useState } from "react";
import Styles from "./UploadDocs.module.css"
import { onValue, ref } from "firebase/database";
import { firebaseDatabase } from "../../../backend/firebaseHandler";
import { Box, CircularProgress, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";

const UploadDocs = () => {

    const [leads, setLeads] = useState([])
    const [loading, setLoading] = useState(true)
    const [userList, setUserList] = useState([])
    const [selectedUser, setSelectedUser] = useState({})
    const navigate = useNavigate()

    useEffect(() => {
        let temp = []
        
        onValue(ref(firebaseDatabase, `USER_ARCHIVE`), (snap) => {
            if (snap.exists()) {
                for (const key in snap.val()) {
                    let item = snap.child(key).val()
                    if (item.position === "Advisor" && item.department === "Education Loan" && item.status === "Active") {
                        console.log(item.name)
                        temp.push(item)
                    }
                }
                setUserList([...temp])
                setSelectedUser(temp[0])
                handleChange(temp[0])
            }
        }, { onlyOnce: true })
    }, [])

    const handleChange = (user) => {
       let temp = []
       let temp2 = []
       
        setLoading(true)
        onValue(ref(firebaseDatabase, `INTERESTED_LEADS/${user.uid}`), (snap) => {
            if (snap.exists()) {
                for (const key in snap.val()) {
                    temp.push(snap.child(key).val())
                }
            }
            temp2 = temp.sort((a, b) => {
                const date_1 = moment(a.interestedDate,"DD-MM-YYYY h:mm a")
                const date_2 = moment(b.interestedDate,"DD-MM-YYYY h:mm a")
                return moment(date_2).diff(date_1)
            })
            setLeads(temp2)
            setLoading(false)
        }, { onlyOnce: true })
    }

    return (
        <div>
            <h1 style={{ color: "#352F6C" }}>Upload docs</h1>

            <div className={Styles.progressComponentContainer}>
                {
                    userList.length > 0
                    ?
                    <>
                        <div>
                            <p style={{ marginTop: 40 }}>Select User</p>
                            <Select style={{ backgroundColor: "white", borderRadius: 5, width: 250, height: 40, border: "none", outline: "none" }} value={selectedUser} onChange={(event) => { setSelectedUser(event.target.value); handleChange(event.target.value) }}>
                                {
                                    userList.map((item, index) => {
                                        return (
                                            <MenuItem value={item}>{item.name}</MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </div>

                        <div className={Styles.progressComponentContainer}>
                            {
                                loading
                                ?
                                <CircularProgress className={Styles.progress}  />
                                :
                                <Box>
                                    <TableContainer>
                                        <Table stickyHeader>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>Name</TableCell>
                                                    <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>Ph Number</TableCell>
                                                    <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>Email ID</TableCell>
                                                    <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>Country</TableCell>
                                                    <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>University</TableCell>
                                                    <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>Interested Date</TableCell>
                                                    <TableCell style={{ backgroundColor: "rgba(1, 1, 1, 0)" }}></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody style={{ backgroundColor: "white" }}>
                                                {
                                                    leads.map((item, index) => {
                                                        return (
                                                            <TableRow key={index.toString()} >
                                                                <TableCell>{item.name}</TableCell>
                                                                <TableCell>xxxxxxxxx{item.phoneNumber.substring(9, 12)}</TableCell>
                                                                <TableCell>{item.emailId}</TableCell>
                                                                <TableCell>{item.desiredCountry}</TableCell>
                                                                <TableCell>{item.desiredUniversity}</TableCell>
                                                                <TableCell>{item.interestedDate?item.interestedDate.split(" ")[0]:""}</TableCell>
                                                                <TableCell style={{fontWeight: 700, color: "#352F6C", cursor:"pointer"}} onClick={()=>{navigate("/upload-docs/document-detail", {state:{item:item}})}}>Detail</TableCell>
                                                            </TableRow>                                                         
                                                        )
                                                    })
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                            }
                        </div>
                    </>
                    :
                    <CircularProgress className={Styles.progress}  />
                }
            </div>
        </div>
    )
}

export default UploadDocs