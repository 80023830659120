import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, Tab } from "@mui/material";
import { HiDocumentCheck } from "react-icons/hi2"

const OperationWithVendorLeads = ({ activeList, type }) => {

    const navigate = useNavigate()
    const [leadList, setLeadList] = useState([])
    const [value, setValue] = React.useState(0);
    
    useEffect(() => {
       handleChange(null, 0) 
    }, [])
    
    const handleChange = async (event, newValue) => {
        setValue(newValue);
        
        let temp = []

        for (const index in activeList) {
            let item = activeList[index]
            if ((newValue === 0 && (!item.isArchived || item.isArchived === false)) || (newValue === 1 && (item.isArchived && item.isArchived === true))) {
                temp.push(item)
            }
        }

        setLeadList([...temp])
    }

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleNavigate = (item, button) => {
        if (button === "file") {
            if (type === "Normal") {
                navigate ("/operations-leads/operation-complete-detail", {state:{item:item}})
            } else if (type === "Incognito") {
                navigate ("/operations-incognito-leads/operation-complete-detail-incognito", {state:{item:item}})
            }
        } else {
            if (type === "Normal") {
                navigate ("/operations-leads/operation-nbfc-detail", {state:{item:item}})
            } else if (type === "Incognito") {
                navigate ("/operations-incognito-leads/operation-nbfc-detail-incognito", {state:{item:item}})
            }
        }
        
    }

    return (
        <div>

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: 20, marginBottom: 40 }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" >
                    <Tab label="Active Cases" {...a11yProps(0)} />
                    <Tab label="Archived Cases" {...a11yProps(1)} />
                </Tabs>
            </div>

            <div style={{width:"100%", marginTop:40}}>
                <Box>
                    <TableContainer>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>#</TableCell>
                                    <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Customer ID</TableCell>
                                    <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>App ID</TableCell>
                                    <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Name</TableCell>
                                    <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>PAN Number</TableCell>
                                    <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Date Received</TableCell>
                                    <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Sent Date</TableCell>
                                    {
                                        type === "Incognito"
                                        &&
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Type</TableCell>
                                    }
                                    <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Advisor Name</TableCell>
                                    <TableCell style={{backgroundColor:"rgba(1, 1, 1, 0)"}}></TableCell>
                                    <TableCell style={{backgroundColor:"rgba(1, 1, 1, 0)"}}></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody style={{backgroundColor:"white"}}>
                                {
                                    leadList.map((item, index) => {
                                        return (
                                            <TableRow key={index.toString()} >
                                                <TableCell>{(index + 1).toString()}</TableCell>
                                                <TableCell>{item.customerId}</TableCell>
                                                <TableCell>{item.applicationId}</TableCell>
                                                <TableCell>{item.name}</TableCell>
                                                <TableCell>{item.caseDetail?item.caseDetail.panNumber:""}</TableCell>
                                                <TableCell>{item.withOperationsDate?item.withOperationsDate.split(" ")[0]:""}</TableCell>
                                                <TableCell>{item.sentToVendorDate?item.sentToVendorDate:""}</TableCell>
                                                {
                                                    type === "Incognito"
                                                    &&
                                                    <TableCell>{item.incognitoType}</TableCell>
                                                }
                                                <TableCell>{item.assignedAdvisorName}</TableCell>
                                                <TableCell><HiDocumentCheck onClick={()=>{handleNavigate(item, "file")}} color={"#352F6C"} size={25} style={{cursor:"pointer"}} /></TableCell>
                                                <TableCell><Button onClick={()=>{handleNavigate(item, "status")}} style={{backgroundColor:"#352F6C", height:30, width:120}} size='medium' variant='contained'>View NBFC</Button></TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </div>
        </div>
    )
}

export default OperationWithVendorLeads