import React, { useContext, useEffect, useState } from "react";
import Styles from "./VendorReLookLeads.module.css"
import { Alert, Box, Button, CircularProgress, Modal, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { onValue, ref, set } from "firebase/database";
import { firebaseDatabase } from "../../../backend/firebaseHandler";
import UserContext from "../../../context/UserData.context";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const VendorReLookLeads = () => {
    
    const [visibleList, setVisibleList] = useState([])
    const [userData, setUserData] = useContext(UserContext)
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const [appIdModal, setAppIdModal] = useState(false)
    const [appId, setAppId] = useState("")
    const [selectedItem, setSelectedItem] = useState({})
    const [snackBar, setSnackBar] = useState({visibility:false, severity:"", message:""})

    useEffect (() => {
        let temp = []

        onValue(ref(firebaseDatabase, `VENDOR_RE_LOOK_LEADS/${userData.nbfcName}`), async (snap) => {
            if (snap.exists()) {
                let num = 0
                temp = []
                for (const key in snap.val()) {
                    let obj = snap.child(key).val()
                    if (obj.assignedRmUid === userData.uid) {
                        temp.push (obj)
                    }
                    
                    num++
                    if (num === snap.size) {
                        setVisibleList(temp)
                        setLoading(false)
                    }
                }
            } else {
                setLoading(false)
            }
        }, {onlyOnce:false})
    }, [])

    const handleAppId = async () => {
        let tempObj = {...selectedItem}
        
        if (appId === "") {
            alert("Please enter the App ID.")
            return
        }

        tempObj.status = "Logged-in"
        tempObj.applicationId = appId
        tempObj.loginDate = moment().format("DD-MM-YYYY h:mm a")
        tempObj.noteFromVendor = ""

        let dbRef = null
        if (tempObj.type && tempObj.type === "Incognito") {
            dbRef = ref(firebaseDatabase, `INCOGNITO_NBFC_LEAD_STATUS/${selectedItem.phoneNumber}/${userData.nbfcName}`)
        } else {
            dbRef = ref(firebaseDatabase, `NBFC_LEAD_STATUS/${selectedItem.phoneNumber}/${userData.nbfcName}`)
        }
        onValue(dbRef, async (snap) => {
            if (snap.exists()) {
                let statusObj = snap.val()
                statusObj.status = "Logged-in"
                statusObj.appId = appId
                statusObj.statusDate = tempObj.loginDate
                statusObj.loginDate = tempObj.loginDate
                statusObj.comment = ""

                let tempUpdateRecord = statusObj.updateRecord?statusObj.updateRecord:[]
                let updateItem = {
                    actionBy: "Vendor",
                    actionOn: tempObj.loginDate,
                    note: appId,
                    actionType: "Logged-in"
                }
                tempUpdateRecord.push(updateItem)
                statusObj.updateRecord = [...tempUpdateRecord]

                if (tempObj.type && tempObj.type === "Incognito") {
                    await set (ref (firebaseDatabase, `INCOGNITO_NBFC_LEAD_STATUS/${selectedItem.phoneNumber}/${userData.nbfcName}`), statusObj)
                } else {
                    await set (ref (firebaseDatabase, `NBFC_LEAD_STATUS/${selectedItem.phoneNumber}/${userData.nbfcName}`), statusObj)
                }
                await set (ref (firebaseDatabase, `VENDOR_FRESH_LEADS/${userData.nbfcName}/${selectedItem.phoneNumber}`), tempObj)
                await set (ref (firebaseDatabase, `VENDOR_RE_LOOK_LEADS/${userData.nbfcName}/${selectedItem.phoneNumber}`), tempObj)

                setAppIdModal(false)
                setSnackBar({visibility:true, message:"Thank you for your response", severity:"success"})
            }
        }, {onlyOnce:true})
        
    }

    return (
        <div>
            <h1 style={{ color: "#352F6C", marginBottom:40 }}>Re-look Leads</h1>

            <div className={Styles.progressComponentContainer}>
                {
                    loading
                    ?
                    <CircularProgress className={Styles.progress} />
                    :
                    <Box>
                        <TableContainer>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>#</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>App ID</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Customer ID</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Name</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Ph Number</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Email ID</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Date Received</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Status</TableCell>
                                        <TableCell style={{backgroundColor:"rgba(1, 1, 1, 0)"}}></TableCell>
                                        <TableCell style={{backgroundColor:"rgba(1, 1, 1, 0)"}}>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody style={{backgroundColor:"white"}}>
                                    {
                                        visibleList.map((item, index) => {
                                            return (
                                                <TableRow>
                                                    <TableCell>{(index + 1).toString()}</TableCell>
                                                    <TableCell>{item.applicationId}</TableCell>
                                                    <TableCell>{item.customerId}</TableCell>
                                                    <TableCell>{item.name}</TableCell>
                                                    <TableCell>{item.phoneNumber}</TableCell>
                                                    <TableCell>{item.emailId}</TableCell>
                                                    <TableCell>{item.sentToVendorDate}</TableCell>
                                                    <TableCell>{item.status}</TableCell>
                                                    <TableCell>
                                                        {
                                                            (item.status === "Re-login" || item.status === "Closed" || item.status === "Approved" || item.status === "Rejected")
                                                            ?
                                                            null
                                                            :
                                                            <Button style={{backgroundColor:"#352F6C", height:30}} size='medium' variant='contained' onClick={()=>{navigate("/vendor-re-look-leads/vendor-complete-detail", {state:{item:item}})}}>View</Button>
                                                        }
                                                    </TableCell>
                                                    <TableCell>
                                                        {
                                                            (!item.applicationId && !(item.status === "Re-login" || item.status === "Closed" || item.status === "Approved" || item.status === "Rejected"))
                                                            ?
                                                            <Button onClick={()=>{setSelectedItem(item);setAppIdModal(true);}} style={{backgroundColor:"#352F6C", height:30, width:100}} size='medium' variant='contained'>App ID</Button>
                                                            :
                                                            null
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                }
            </div>

            <Modal open={appIdModal} onClose={() => { setAppIdModal(false) }} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={{width:"100%", height:"100%", display:"flex", alignItems:"center", justifyContent:"center"}}>
                    <div className={Styles.modalContainer}>
                        <p className={Styles.modalTitle} style={{fontWeight:400, marginBottom:10}}>Please enter the Application ID below</p>
                        <input value={appId} onChange={(event)=>{setAppId(event.target.value)}} className={Styles.inputField} placeholder="Application ID" InputProps={{ disableUnderline: true }} variant="standard" />

                        <div className={Styles.buttonContainer}>
                            <Button sx={{backgroundColor:"rgba(53, 47, 108, 0.5)"}} onClick={() => { setAppIdModal(false) }} variant='contained'  className={Styles.secondaryButton}>Cancel</Button>
                            <Button onClick={() => { handleAppId()}} variant='contained'  className={Styles.primaryButton}>Update</Button>
                        </div>
                    </div>
                </Box>
            </Modal>

            <Snackbar open={snackBar.visibility} autoHideDuration={3000} onClose={() => { setSnackBar({visibility:false, message:"", severity:""}) }}>
                <Alert onClose={() => { setSnackBar({visibility:false, message:"", severity:""}) }} severity={snackBar.severity} variant="filled" sx={{ width: '100%' }}>{snackBar.message}</Alert>
            </Snackbar>
        </div>
    )
}

export default VendorReLookLeads