import React, { useState } from "react";
import Styles from "./OperationsDocumentUploadPage.module.css"
import { useLocation } from "react-router-dom";
import { Alert, Snackbar, Tab, Tabs } from "@mui/material";
import { FaExternalLinkSquareAlt } from "react-icons/fa";
import { getDownloadURL, ref as storageRef, uploadBytesResumable } from "firebase/storage";
import { firebaseDatabase, firebaseStorage } from "../../backend/firebaseHandler";
import { onValue, ref, set } from "firebase/database";

const OperationsDocumentUploadPage = () => {

    const location = useLocation()
    const [application, setApplication] = useState(location.state.item)
    const [value, setValue] = React.useState(0);
    const [submitting, setSubmitting] = useState(false)
    const [snackBar, setSnackBar] = useState({ visibility: false, severity: "", message: "" })

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }
    
    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleDocumentUpload = (item, folderName, index, key) => {
        const inputElement = document.createElement('input');
        inputElement.setAttribute('type', "file");
        inputElement.setAttribute('accept', 'application/pdf');
        inputElement.onchange = (event) => {
            const files = event.target.files;

            let name = files[0].name
            let nameArray = name.split(".")
            let lastItem = nameArray[nameArray.length - 1]
            if (lastItem !== "pdf") {
                setSnackBar({ visibility: true, severity: "warning", message: "Please upload a PDF file." })
            } else {
                const fileRef = storageRef(firebaseStorage, `EDUCATION_LOAN_DOCS/${application.key}/${folderName}/${item.label}/${Date.now().toString()}.pdf`);
                setSubmitting(true)
                const uploadTask = uploadBytesResumable(fileRef, files[0], { contentType: 'application/pdf' });
                uploadTask.on('state_changed', (snapshot) => {

                }, (error) => {
                    setSnackBar({ visibility: true, severity: "error", message: "Something went wrong" })
                }, async () => {
                    const url = await getDownloadURL(fileRef);
                    item.url = url
                    let tempObj = { ...application }
                    tempObj[key][index] = { ...item }
                    setApplication({ ...tempObj })
                    setSubmitting(false)
                })
            }
        }
        inputElement.click();

        // item.url = "url"
        // let tempObj = {...application}
        // tempObj[key][index] = {...item}
        // setApplication({...tempObj})
        // setSubmitting(false)
    }
    
    const handleNestedDocumentUpload = (item, folderName, index, index2, key) => {
        const inputElement = document.createElement('input');
        inputElement.setAttribute('type', "file");
        inputElement.setAttribute('accept', 'application/pdf');
        inputElement.onchange = (event) => {
            const files = event.target.files;

            if (files[0].name.split(".")[1] !== "pdf") {
                setSnackBar({ visibility: true, severity: "warning", message: "Please upload a PDF file." })
            } else {
                const fileRef = storageRef(firebaseStorage, `EDUCATION_LOAN_DOCS/${application.key}/${folderName}/${item.label}/${Date.now().toString()}`);
                setSubmitting(true)
                const uploadTask = uploadBytesResumable(fileRef, files[0], { contentType: 'application/pdf' });
                uploadTask.on('state_changed', (snapshot) => {

                }, (error) => {
                    setSnackBar({ visibility: true, severity: "error", message: "Something went wrong" })
                }, async () => {
                    const url = await getDownloadURL(fileRef);
                    item.url = url
                    let tempObj = { ...application }
                    tempObj[key][index][index2] = { ...item }
                    setApplication({ ...tempObj })
                    setSubmitting(false)
                })
            }
        }
        inputElement.click();

        // item.url = "url"
        // let tempObj = {...application}
        // tempObj[key][index][index2] = {...item}
        // setApplication({...tempObj})
        // setSubmitting(false)
    }

    const handleSave = async () => {
        setSubmitting(true)

        onValue(ref(firebaseDatabase, `INTERESTED_LEADS/${application.assignedAdvisorUid}/${application.phoneNumber}`), async (snap) => {
            if (snap.exists()) {
                if (application.assignedAdvisorUid && application.phoneNumber) {
                    await set(ref(firebaseDatabase, `INTERESTED_LEADS/${application.assignedAdvisorUid}/${application.phoneNumber}`), application)
                    await set(ref(firebaseDatabase, `LOAN_APPLICATIONS/${application.phoneNumber}`), application)
                    setSnackBar({visibility:true, severity:"success", message:"Changes saved."})
                    setSubmitting(false)
                }
            } else {
                setSnackBar({visibility:true, severity:"error", message:"The lead appears to have been moved."})
                setSubmitting(false)
            }
        }, {onlyOnce:true})
    }

    return (
        <div>
            <h1 style={{ color: "#352F6C" }}>{application.name}</h1>
            <p style={{ color: "#352F6C", fontSize:"18px" }}>Advisor - {application.assignedAdvisorName}</p>

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: 20, marginBottom: 40 }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" >
                    <Tab label="Upload App Docs" {...a11yProps(1)} />
                    <Tab label="Upload CS Docs" {...a11yProps(2)} />
                    <Tab label="Upload NF Docs" {...a11yProps(3)} />
                </Tabs>
            </div>

            {
                value === 0
                &&
                <>
                    <div className={Styles.documentListContainer}>
                        {
                            !application.applicantDocs && !application.examDocs && !application.currentlyInIndia
                                ?
                                <p className={Styles.informationMessage}>Complete information not available to generate LOD</p>
                                :
                                application.applicantDocs
                                    ?
                                    application.applicantDocs.map((item, index) => {
                                        return (
                                            <div className={Styles.documentItem}>
                                                <p onClick={() => { handleDocumentUpload(item, "Applicant Docs", index, "applicantDocs") }} style={{ color: item.url === "" ? null : "#12b004" }} className={Styles.documentName}>{item.name}</p>
                                                <FaExternalLinkSquareAlt onClick={() => { window.open(item.url, "_blank") }} color={item.url === "" ? "" : "#12b004"} style={{ cursor: "pointer" }} />
                                            </div>
                                        )
                                    })
                                    :
                                    null
                        }
                    </div>
                    <div style={{ marginTop: 20 }} className={Styles.documentListContainer}>
                        {
                            application.abroadDocs
                            &&
                            application.abroadDocs.map((item, index) => {
                                return (
                                    <div className={Styles.documentItem}>
                                        <p onClick={() => { handleDocumentUpload(item, "Applicant Docs", index, "abroadDocs") }} style={{ color: item.url === "" ? null : "#12b004" }} className={Styles.documentName}>{item.name}</p>
                                        <FaExternalLinkSquareAlt onClick={() => { window.open(item.url, "_blank") }} color={item.url === "" ? "" : "#12b004"} style={{ cursor: "pointer" }} />
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div style={{ marginTop: 20 }} className={Styles.documentListContainer}>
                        {
                            application.examDocs
                            &&
                            application.examDocs.map((item, index) => {
                                return (
                                    <div className={Styles.documentItem}>
                                        <p onClick={() => { handleDocumentUpload(item, "Applicant Docs", index, "examDocs") }} style={{ color: item.url === "" ? null : "#12b004" }} className={Styles.documentName}>{item.name}</p>
                                        <FaExternalLinkSquareAlt onClick={() => { window.open(item.url, "_blank") }} color={item.url === "" ? "" : "#12b004"} style={{ cursor: "pointer" }} />
                                    </div>
                                )
                            })
                        }
                    </div>
                </>
            }
            {
                value === 1
                &&
                <div className={Styles.documentListContainer}>
                    {
                        application.coApplicantDocs
                            ?
                            application.coApplicantDocs.map((item, index) => {
                                return (
                                    <div className={Styles.documentItem}>
                                        <p onClick={() => { handleDocumentUpload(item, "Co-Applicant Docs", index, "coApplicantDocs") }} style={{ color: item.url === "" ? null : "#12b004" }} className={Styles.documentName}>{item.name}</p>
                                        <FaExternalLinkSquareAlt onClick={() => { window.open(item.url, "_blank") }} color={item.url === "" ? "" : "#12b004"} style={{ cursor: "pointer" }} />
                                    </div>
                                )
                            })
                            :
                            <p className={Styles.informationMessage}>Complete information not available to generate LOD</p>
                    }
                    {
                        application.abroadCoAppDocs
                        &&
                        application.abroadCoAppDocs.map((item, index) => {
                            return (
                                <div className={Styles.documentItem}>
                                    <p onClick={() => { handleDocumentUpload(item, "Co-Applicant Docs", index, "abroadCoAppDocs") }} style={{ color: item.url === "" ? null : "#12b004" }} className={Styles.documentName}>{item.name}</p>
                                    <FaExternalLinkSquareAlt onClick={() => { window.open(item.url, "_blank") }} color={item.url === "" ? "" : "#12b004"} style={{ cursor: "pointer" }} />
                                </div>
                            )
                        })
                    }
                </div>
            }
            {
                value === 2
                &&
                <div>
                    {
                        application.nonFinancialCoApplicants
                            ?
                            <>
                                {
                                    application.nonFinancialCoApplicants.map((item, index) => {
                                        return (
                                            <div>
                                                <p className={Styles.nonFinRelationLabel}>{item}</p>
                                                <div className={Styles.documentListContainer}>
                                                    {
                                                        application.nonFinancialDocs[index].map((item2, index2) => {
                                                            return (
                                                                <div className={Styles.documentItem}>
                                                                    <p onClick={() => { handleNestedDocumentUpload(item2, "Non Financial Applicant Docs", index, index2, "nonFinancialDocs") }} style={{ color: item2.url === "" ? null : "#12b004" }} className={Styles.documentName}>{item2.name}</p>
                                                                    <FaExternalLinkSquareAlt onClick={() => { window.open(item2.url, "_blank") }} color={item2.url === "" ? "" : "#12b004"} style={{ cursor: "pointer" }} />
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                <div>
                                    <p className={Styles.nonFinRelationClickableLabel} >Additional Non-financial Applicant</p>
                                    {
                                        application.additionalNonFinancialRelation
                                        &&
                                        application.additionalNonFinancialRelation.map((item, index) => {
                                            return (
                                                <div>
                                                    <div style={{display:"flex", flexDirection:"row", gap:10, alignItems:"center"}}>
                                                        <p className={Styles.nonFinRelationLabel}>{item}</p>
                                                    </div>
                                                    <div className={Styles.documentListContainer}>
                                                        {
                                                            application.additionalNonFinancialDocs[index].map((item2, index2) => {
                                                                return (
                                                                    <div className={Styles.documentItem}>
                                                                        <p onClick={() => { handleNestedDocumentUpload(item2, "Additional Non Financial Applicant Docs", index, index2, "additionalNonFinancialDocs") }} style={{ color: item2.url === "" ? null : "#12b004" }} className={Styles.documentName}>{item2.name}</p>
                                                                        <FaExternalLinkSquareAlt onClick={() => { window.open(item2.url, "_blank") }} color={item2.url === "" ? "" : "#12b004"} style={{ cursor: "pointer" }} />
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <div>
                                    <p className={Styles.nonFinRelationLabel}>Location Guarantor Documents</p>
                                    <div className={Styles.documentListContainer}>
                                        {
                                            application.locationGuarantorDocs
                                                ?
                                                application.locationGuarantorDocs.map((item, index) => {
                                                    return (
                                                        <div className={Styles.documentItem}>
                                                            <p onClick={() => { handleDocumentUpload(item, "Location Guarantor Docs", index, "locationGuarantorDocs") }} style={{ color: item.url === "" ? null : "#12b004" }} className={Styles.documentName}>{item.name}</p>
                                                            <FaExternalLinkSquareAlt onClick={() => { window.open(item.url, "_blank") }} color={item.url === "" ? "" : "#12b004"} style={{ cursor: "pointer" }} />
                                                        </div>
                                                    )
                                                })
                                                :
                                                <p>None</p>
                                        }
                                    </div>
                                </div>
                                <div>
                                    <p className={Styles.nonFinRelationClickableLabel} >Additional Documents</p>
                                    <div className={Styles.documentListContainer}>
                                        {
                                            application.additionalDocuments
                                                ?
                                                application.additionalDocuments.map((item, index) => {
                                                    return (
                                                        <div className={Styles.documentItem}>
                                                            <p onClick={() => { handleDocumentUpload(item, "Additional Docs", index, "additionalDocuments") }} style={{ color: item.url === "" ? null : "#12b004" }} className={Styles.documentName}>{item.name}</p>
                                                            <FaExternalLinkSquareAlt onClick={() => { window.open(item.url, "_blank") }} color={item.url === "" ? "" : "#12b004"} style={{ cursor: "pointer" }} />
                                                        </div>
                                                    )
                                                })
                                                :
                                                <p>None</p>
                                        }
                                    </div>
                                </div>
                            </>
                            :
                            <p className={Styles.informationMessage}>Complete information not available to generate LOD</p>
                    }
                </div>
            }

            <button disabled={submitting} style={{backgroundColor:submitting?"#ccc":null}} onClick={handleSave} className={Styles.button}>Submit</button>

            <Snackbar open={snackBar.visibility} autoHideDuration={3000} onClose={() => { setSnackBar({ visibility: false, message: "", severity: "" }) }}>
                <Alert onClose={() => { setSnackBar({ visibility: false, message: "", severity: "" }) }} severity={snackBar.severity} variant="filled" sx={{ width: '100%' }}>{snackBar.message}</Alert>
            </Snackbar>
        </div>
    )
}

export default OperationsDocumentUploadPage