import { get, onValue, orderByChild, orderByValue, push, query, ref, remove, set } from "firebase/database";
import React, { useState } from "react";
import { firebaseDatabase, firebaseDatabaseAdmin, firebaseDatabaseFidesure, firebaseDatabaseSC } from "../../backend/firebaseHandler";
import { chain } from "lodash";
import moment from "moment";
import slugify from "slugify";
import exportFromJSON from "export-from-json";
import getNbfcList from "../../workload/NbfcList";
import * as XLSX from 'xlsx';

const ForTesting = () => {


    const handleUpdate = async () => {

        // let array = ["919360508477", "919381514529", "919381517513", "919390488841", "919392000069", "919398489652", "919398574592", "919489953706", "919505184621", "919515400670", "919517960116", "919542145669", "919542381721", "919550394068", "919553574764", "919588611284", "919676764560", "919701960385", "919754412002", "919848772271", "919872078699", "919873675533", "919876511292", "919877397056", "919912992642"]

        // for (const index in array) {
        //     let phoneNumber = array[index]
            
        //     if (phoneNumber) {
        //         await set(ref(firebaseDatabase, `NBFC_LEAD_STATUS/${phoneNumber}/TataCapital/assignedRmName`), "Simranjeet Kaur")
        //         await set(ref(firebaseDatabase, `NBFC_LEAD_STATUS/${phoneNumber}/TataCapital/assignedRmPhoneNumber`), "8285100265")
        //         await set(ref(firebaseDatabase, `NBFC_LEAD_STATUS/${phoneNumber}/TataCapital/assignedRmUid`), "pFiBdD9L54Z1YTrGLJo9BZ3HNGm2")

        //         await set(ref(firebaseDatabase, `VENDOR_FRESH_LEADS/TataCapital/${phoneNumber}/assignedRmName`), "Simranjeet Kaur")
        //         await set(ref(firebaseDatabase, `VENDOR_FRESH_LEADS/TataCapital/${phoneNumber}/assignedRmPhoneNumber`), "8285100265")
        //         await set(ref(firebaseDatabase, `VENDOR_FRESH_LEADS/TataCapital/${phoneNumber}/assignedRmUid`), "pFiBdD9L54Z1YTrGLJo9BZ3HNGm2")
        //     }
            
        // }
        // console.log("done")




        // handleSCSide("916309656123", "vCc71bC32veDb4rIzPO8CgQGRvH3", "Khushi Banjeriya", "EFDNnrNfVfhberstedJo7BDpwRt2", "Garvit Sagar", "8383917323")
        handleVendorSide("916309656123", "EFDNnrNfVfhberstedJo7BDpwRt2", "Garvit Sagar", "8383917323")
    }

    const handleSCSide = (phoneNumber, sourceUid, sourceName, targetUid, targetName, targetNumber) => {

        onValue(ref(firebaseDatabase, `WITH_VENDORS_LEADS/${sourceUid}/${phoneNumber}`), async (snap) => {
            if (snap.exists()) {
                let item = snap.val()
                item.assignedAdvisorName = targetName
                item.assignedAdvisorPhoneNumber = targetNumber
                item.assignedAdvisorUid = targetUid
                item.reAssigningDate = moment().format("DD-MM-YYYY h:mm a")
                item.reAssignmentDetail = {
                    reason: "Advior In-active",
                    previousAdvisorName: sourceName,
                    previousAdvisorUid: sourceUid
                }

                await set(ref(firebaseDatabase, `WITH_VENDORS_LEADS/${targetUid}/${phoneNumber}`), item)
                await set(ref(firebaseDatabase, `OP_USER_WITH_VENDORS_LEADS/${phoneNumber}`), item)
                await set(ref(firebaseDatabase, `LOAN_APPLICATIONS/${phoneNumber}`), item)
                await remove(ref(firebaseDatabase, `WITH_VENDORS_LEADS/${sourceUid}/${phoneNumber}`))
                console.log("done")
            } else {
                console.log("Nahi mila")
            }
        }, {onlyOnce:true})
    }

    const handleVendorSide = (phoneNumber, targetUid, targetName, targetNumber) => {
        console.log("starting")

        onValue(ref(firebaseDatabase, `NBFC_LEAD_STATUS/${phoneNumber}`), async (snap) => {
            if (snap.exists()) {
                for (const key in snap.val()) {
                    let status = snap.child(key).child("status").val()
                    let vendorTablName = ""
                    switch (status) {
                        case "Pool": {
                            vendorTablName = `VENDOR_FILE_POOL`
                            break;
                        }
                        case "Not Shared": {
                            vendorTablName = `VENDOR_FILE_POOL`
                            break;
                        }
                        case "In-review": {
                            vendorTablName = `VENDOR_LEADS`
                            break;
                        }
                        case "Fresh": {
                            vendorTablName = `VENDOR_FRESH_LEADS`
                            break;
                        }
                        case "Re-login": {
                            vendorTablName = `VENDOR_RE_LOGIN_LEADS`
                            break;
                        }
                        case "Re-look": {
                            vendorTablName = `VENDOR_RE_LOOK_LEADS`
                            break;
                        }
                        case "Re-check": {
                            vendorTablName = `VENDOR_FRESH_LEADS`
                            break;
                        }
                        case "Incomplete File": {
                            vendorTablName = `VENDOR_FRESH_LEADS`
                            break;
                        }
                        case "Follow Up": {
                            vendorTablName = `VENDOR_FRESH_LEADS`
                            break;
                        }
                        case "On-hold": {
                            vendorTablName = `VENDOR_FRESH_LEADS`
                            break;
                        }
                        case "Closed": {
                            vendorTablName = `VENDOR_CLOSED_LEADS`
                            break;
                        }
                        case "Updated": {
                            vendorTablName = `VENDOR_FRESH_LEADS`
                            break;
                        }
                        case "Not Contactable": {
                            vendorTablName = `VENDOR_FRESH_LEADS`
                            break;
                        }
                        case "Not Doable": {
                            vendorTablName = `VENDOR_FRESH_LEADS`
                            break;
                        }
                        case "Logged-in": {
                            vendorTablName = `VENDOR_FRESH_LEADS`
                            break;
                        }
                        case "Approved": {
                            vendorTablName = `VENDOR_APPROVED_LEADS`
                            break;
                        }
                        case "Rejected": {
                            vendorTablName = `VENDOR_REJECTED_LEADS`
                            break;
                        }
                        case "Shared": {
                            vendorTablName = `VENDOR_APPROVED_LEADS`
                            break;
                        }
                        case "Rejected by Student": {
                            vendorTablName = `VENDOR_AUTO_REJECT_LEADS`
                            break;
                        }
                        case "Accepted by Student": {
                            vendorTablName = `VENDOR_APPROVED_LEADS`
                            break;
                        }
                        case "Disbursed": {
                            vendorTablName = `VENDOR_DISBURSED_LEADS`
                            break;
                        }
                        case "SL Received": {
                            vendorTablName = `VENDOR_SL_SENT_LEADS`
                            break;
                        }
                        default: {
                            console.log("Not present for: " + key)
                        }
                    }

                    await set(ref(firebaseDatabase, `${vendorTablName}/${key}/${phoneNumber}/assignedAdvisorName`), targetName)
                    await set(ref(firebaseDatabase, `${vendorTablName}/${key}/${phoneNumber}/assignedAdvisorPhoneNumber`), targetNumber)
                    await set(ref(firebaseDatabase, `${vendorTablName}/${key}/${phoneNumber}/assignedAdvisorUid`), targetUid)

                    console.log("Done with " + key + " at " + status)
                }
            }
        }, {onlyOnce:true})
    }

    const handleTransfer = () => {
        onValue(ref(firebaseDatabase, `DISBURSED_LEADS`), (snap) => {
            if (snap.exists()) {
                for (const uid in snap.val()) {
                    for (const number in snap.child(uid).val()) {
                        console.log(snap.child(uid).child(number).child("assignedAdvisorName").val())
                    }
                }
            }
        }, {onlyOnce:true})
    }

    const movingInterested = () => {
        onValue(ref(firebaseDatabase, "INTERESTED_LEADS/EFDNnrNfVfhberstedJo7BDpwRt2/919500606429"), async (snap) => {
            if (snap.exists()) {
                let item = snap.val()
                item.assignedAdvisorName = "Meenakshi Sharma"
                item.assignedAdvisorPhoneNumber = "8882994260"
                item.assignedAdvisorUid = "ib3qWcwVDbfctClRlIu07lX0BA23"
                item.reAssigningDate = moment().format("DD-MM-YYYY h:mm a")
                item.reAssignmentDetail = {
                    reason: "Transferred for documents",
                    previousAdvisorName: "Garvit Sagar",
                    previousAdvisorUid: "EFDNnrNfVfhberstedJo7BDpwRt2"
                }

                await set(ref(firebaseDatabase, "LOAN_APPLICATIONS/919500606429"), item)
                await set(ref(firebaseDatabase, "INTERESTED_LEADS/ib3qWcwVDbfctClRlIu07lX0BA23/919500606429"), item)
                await remove(ref(firebaseDatabase, "INTERESTED_LEADS/EFDNnrNfVfhberstedJo7BDpwRt2/919500606429"))
                console.log("Done")
            } else {
                console.log("Oops")
            }
        }, {onlyOnce:true})
    }

    const statusReport = () => {
        let numbers = [
            "919392980080",
            "918667796899",
            "918091734526",
            "919494047153",
            "919538081822",
            "919466562343",
            "918688143240",
            "919669797672",
            "918946946262",
            "917019479412",
            "919284723953",
            "919879782612",
            "918712233357",
            "917989994853",
            "917710072017",
            "919700006706",
            "917013660792",
            "917088835421",
            "918383008370",
            "918861785278",
            "919494500114",
            "919833662933",
            "918179725010",
            "917022615130",
            "919741111006",
            "919381280005",
            "918008660107",
            "919836780240",
            "917758900668",
            "919059109368",
            "919848565375",
            "919705731585",
            "917075086386",
            "919048801872",
            "917993245243",
            "918307770333",
            "919100724459",
            "916351745216",
            "919110529133",
            "916302325007",
            "919704909543",
            "916289799664",
            "917503758135",
            "918332980094",
            "919890150701",
            "919829386374",
            "919403663983",
            "919160902989",
            "919542375028",
            "919347495616",
            "919353634539",
            "916395099909",
            "919757397204",
            "919108781967",
            "919629771491",
            "918977794710",
            "919662537017",
            "917013359644",
            "916306449781",
            "918860584899",
            "919643517179",
            "919553555919",
            "917982267447",
            "919561123245",
            "919591153001",
            "918143663734",
            "918882794428",
            "918688481272",
            "917674858771",
            "916375826592",
            "918800128838",
            "919869783308",
            "917011098754",
            "917995351557",
            "917820834839",
            "916305401768",
            "918310577707",
            "919522927262",
            "918147229286",
            "919660003362",
            "918149661541",
            "919704742673",
            "919145007993",
            "919711034458",
            "919980151129",
            "916362045223",
            "919967210387",
            "916352105324",
            "916375331019",
            "917025709611",
            "917305417211",
            "917339030987",
            "917416412434",
            "917592997734",
            "917995153934",
            "918017854348",
            "918107076254",
            "918237624149",
            "918270376156",
            "918608118007",
            "919000903693",
            "919004282281",
            "919004374825",
            "919026403979",
            "919431273485",
            "919447483561",
            "919632495328",
            "919705900333",
            "919712019672",
            "919817806665",
            "919582105997",
            "919353736301",
            "917088210592",
            "919912332530",
            "919390241415",
            "917013312089",
            "917619204875",
            "918356820875",
            "919945525217",
            "917756000338",
            "916372812450",
            "919014594908",
            "916263011754",
            "919080641047",
            "917045131015",
            "919327633650",
            "919426439137",
            "917760984915",
            "918095454986",
            "919137803978",
            "917070406839",
            "919873129012",
            "918732076888",
            "919342721791",
            "916304821132",
            "917042724880",
            "919912266699",
            "917063992024",
            "919603928118",
            "918287261740",
            "919390571269",
            "918374052564",
            "917006450813",
            "918850654805",
            "919951434739",
            "919589693492",
            "916290230849",
            "919449665061",
            "918187874162",
            "916374501165",
            "916383588180",
            "916385331727",
            "917506824354",
            "917058372574",
            "919833446921",
            "918770337346",
            "919607780081",
            "919667328885",
            "919056529849",
            "919392971141",
            "919010897419",
            "919515758904",
            "917984185862",
            "918130721578",
            "918377966025",
            "917842960296",
            "917997697646"
        ]
        let temp = []

        // Create an array of promises
        let promises = numbers.map((phoneNumber) => {
            return new Promise((resolve) => {
                onValue(ref(firebaseDatabase, `INCOGNITO_LOAN_APPLICATIONS/${phoneNumber}`), (snap) => {
                    if (snap.exists()) {
                        let item = snap.val()
                        let tempObj = {
                            "Student Name": item.name || "",
                            "Phone Number": item.phoneNumber || "",
                            "Email-ID": item.emailId || "",
                            "Assigned on": item.newFromAdminDate || "",
                            "Advisor": item.assignedAdvisorName || "",
                            "Status": item.status || "",
                            "Sub Status": item.subStatus || "",
                            "Source": item.source || "",
                            "Source Description": item.sourceDescription || ""
                        }
                        
                        
                        temp.push(tempObj)
                    } 
                    resolve() // Resolve the promise once the onValue function is complete
                }, { onlyOnce: true })
            })
        })

        // Wait for all promises to complete before exporting the file
        Promise.all(promises).then(() => {
            const fileName = 'Leads March Incog Status'
            const exportType = exportFromJSON.types.csv
            exportFromJSON({ data: temp, fileName, exportType })
        })
    }

    const dataExtraction = () => {
        let temp = []

        onValue(ref(firebaseDatabase, "INCOGNITO_NEW_LEADS/dADOfv9Jbga38nsIDgSdoohwZ1O2"), async (snap) => {
            if (snap.exists()) {
                for (const uid in snap.val()) {
                    let item = snap.child(uid).val()

                    
                    if (item.subStatus.includes("Yet to Call")) {
                        let tempObj = {
                            "Student Name": item.name || "",
                            "Phone Number": item.phoneNumber || "",
                            "Email-ID": item.emailId || "",
                            "Assigned on": item.newFromAdminDate || "",
                            "Advisor": item.assignedAdvisorName || "",
                            "Status": item.status || "",
                            "Sub Status": item.subStatus || "",
                            "Source": item.source || "",
                            "Source Description": item.sourceDescription || ""
                        }
                        temp.push(tempObj)
                    }
                    
                }
                const fileName = 'Sanya New'
                const exportType = exportFromJSON.types.csv
                exportFromJSON({ data: temp, fileName, exportType })
                
            }
        }, {onlyOnce:true})
    }

    const dataDeletion = () => {
        
        let temp = []
        let count = 0
        onValue(ref(firebaseDatabase, "INCOGNITO_NEW_LEADS/dADOfv9Jbga38nsIDgSdoohwZ1O2"), async (snap) => {
            if (snap.exists()) {
                for (const uid in snap.val()) {
                    let item = snap.child(uid).val()

                    
                    if (item.subStatus.includes("Yet to Call")) {
                        if (item.phoneNumber) {
                            console.log(item.phoneNumber + "  " + count++)
                            await remove(ref(firebaseDatabase, `INCOGNITO_LOAN_APPLICATIONS/${item.phoneNumber}`))
                            await remove(ref(firebaseDatabase, `ALL_LEADS_STATUS/Incognito/${item.phoneNumber}`))
                            await remove(ref(firebaseDatabase, `INCOGNITO_NEW_LEADS/dADOfv9Jbga38nsIDgSdoohwZ1O2/${item.phoneNumber}`))
                        }
                        
                    }
                }
                console.log("Done")
                
            }
        }, {onlyOnce:true})

        
    }

    const dataCount = () => {
        console.log("here")
        onValue(ref(firebaseDatabase, "INCOGNITO_CLOSED_LEADS/Z6H4JQ4TupcAAiaCkbrblr1rqJ73"), async (snap) => {
            if (snap.exists()) {
                for (const uid in snap.val()) {
                    let item = snap.child(uid).val()

                    if (item.subStatus.includes("Not Interested")) {
                        console.log(item.assignedAdvisorName)
                    }
                    // for (const key in snap.child(uid).val()) {
                    //     let item = snap.child(uid).child(key).val()

                    //     if (item.subStatus === "From Admin-Called") {
                    //         console.log(item.assignedAdvisorName)
                    //     }
                    // }
                }
                console.log("Done")
                
            } else {
                console.log("non")
            }
        }, {onlyOnce:true})
    }

    const dataValidation = () => {
        let temp = []
        onValue(ref(firebaseDatabase, "INCOGNITO_NEW_LEADS/dADOfv9Jbga38nsIDgSdoohwZ1O2"), async (snap) => {
            if (snap.exists()) {
                for (const uid in snap.val()) {
                    let item = snap.child(uid).val()

                    if (item.subStatus.includes("Yet to Call")) {
                        get(ref(firebaseDatabase, `ALL_LEADS_STATUS/Incognito/${item.phoneNumber}/status`)).then((snap) => {
                            if(snap.exists()) {
                                console.log(snap.val())
                            }
                        })
                    }

                    // get(ref(firebaseDatabase, `INCOGNITO_LOAN_APPLICATIONS/${item.phoneNumber}/assignedAdvisorName`)).then((snap) => {
                    //     if(snap.exists()) {
                    //         console.log(snap.val())
                    //     }
                    // })

                    // get(ref(firebaseDatabase, `ALL_LEADS_STATUS/Incognito/${item.phoneNumber}/status`)).then((snap) => {
                    //     if(snap.exists()) {
                    //         console.log(snap.val())
                    //     }
                    // })
                    
                }
                console.log("Done")
                
            }
        }, {onlyOnce:true})
    }

    const documentReport = () => {
        let temp = []
        onValue(ref(firebaseDatabase, "INTERESTED_LEADS/ib3qWcwVDbfctClRlIu07lX0BA23"), (snap) => {
            if (snap.exists()) {
                for (const key in snap.val()) {
                    let item = snap.child(key).val()
                    let applicantDocString = ""
                    let coApplicantDocString = ""
                    let nfApplicantDocString = ""

                    if (item.applicantDocs) {
                        for (const index in item.applicantDocs) {
                            let docItem = item.applicantDocs[index]
                            if (docItem.url !== "") {
                                applicantDocString += docItem.name + ", "
                            }
                        }
                    }

                    if (item.coApplicantDocs) {
                        for (const index in item.coApplicantDocs) {
                            let docItem = item.coApplicantDocs[index]
                            if (docItem.url !== "") {
                                coApplicantDocString += docItem.name + ", "
                            }
                        }
                    }

                    if (item.nonFinancialDocs) {
                        for (const index in item.nonFinancialDocs) {
                            for (const index2 in item.nonFinancialDocs[index]) {
                                let docItem = item.nonFinancialDocs[index][index2]
                                if (docItem.url !== "") {
                                    nfApplicantDocString += item.nonFinancialCoApplicants[index] + " " + docItem.name + ", "
                                }
                            } 
                        }
                    }

                    let tempObj = {
                        "Student Name": item.name || "",
                        "Phone Number": item.phoneNumber || "",
                        "Email-ID": item.emailId || "",
                        "Assigned on": item.newFromAdminDate || "",
                        "Interested on": item.interestedDate || "",
                        "Source": item.source || "",
                        "Source Description": item.sourceDescription || "",
                        "Applicant Documents": applicantDocString || "",
                        "Co-Applicant Documents": coApplicantDocString || "",
                        "Non-Financial Documents": nfApplicantDocString || ""
                    }
                    temp.push(tempObj)
                }
                const fileName = 'Meenkashi Sharma Documents'
                const exportType = exportFromJSON.types.csv
                exportFromJSON({ data: temp, fileName, exportType })
            }
        }, {onlyOnce:true})
    }

    const countFinals = () => {
        onValue(ref(firebaseDatabase, `VENDOR_APPROVED_LEADS`), (snap) => {
            if (snap.exists()) {
                for (const uid in snap.val()) {
                    for (const phoneNumber in snap.child(uid).val()) {
                        let item = snap.child(uid).child(phoneNumber).val()

                        if (item.fromVendorStatusDate && item.fromVendorStatusDate.includes("03-2025")) {
                            console.log(item.assignedAdvisorName)
                        }
                    }
                }
            }
        }, {onlyOnce:true})
    }

    return (
        <div style={{padding:50}}>
            <h1 onClick={()=>{handleUpdate()}}>Update</h1>
        </div>
    )
}

export default ForTesting


