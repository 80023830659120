import React, { useContext, useEffect, useState } from "react";
import Styles from "./AdvisorContestLeads.module.css"
import { onValue, ref } from "firebase/database";
import { firebaseDatabase } from "../../../backend/firebaseHandler";
import UserContext from "../../../context/UserData.context";
import { Box, Button, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useNavigate } from "react-router-dom";

const AdvisorContestLeads = () => {

    const [loading, setLoading] = useState(true)
    const [list, setList] = useState([])
    const [userData] = useContext(UserContext)

    const navigate = useNavigate()

    useEffect(() => {
        let temp = []

        onValue(ref(firebaseDatabase, `CONTEST_LEADS/${userData.uid}`), (snap) => {
            if (snap.exists()) {
                for (const key in snap.val()) {
                    temp.push(snap.child(key).val())
                }
            }
            setList(temp)
        }, {onlyOnce:true})
    }, [])

    return (
        <div>
            <div>
                <h1 style={{ color: "#352F6C" }}>Contest Leads</h1>
            </div>
            <div>
                <Box>
                    <TableContainer>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>#</TableCell>
                                    <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>Customer Id</TableCell>
                                    <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>Name</TableCell>
                                    <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>Ph Number</TableCell>
                                    <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>Email ID</TableCell>
                                    <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>Country</TableCell>
                                    <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>University</TableCell>
                                    <TableCell style={{ backgroundColor: "rgba(1, 1, 1, 0)" }}></TableCell>
                                    <TableCell style={{ backgroundColor: "rgba(1, 1, 1, 0)" }}></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody style={{ backgroundColor: "white" }}>
                                {
                                    list.map((item, index) => {
                                        return (
                                            <TableRow key={index.toString()} >
                                                <TableCell>{(index + 1).toString()}</TableCell>
                                                <TableCell>{item.customerId}</TableCell>
                                                <TableCell>{item.name}</TableCell>
                                                <TableCell>xxxxxxxxx{item.phoneNumber?item.phoneNumber.substring(9, 12):"xxx"}</TableCell>
                                                <TableCell>{item.emailId}</TableCell>
                                                <TableCell>{item.desiredCountry}</TableCell>
                                                <TableCell>{item.desiredUniversity}</TableCell>
                                                <TableCell><Button onClick={()=>{navigate("/sc-view-file", {state:{item:item}})}} style={{backgroundColor:"#352F6C", height:30, width:120}} size='medium' variant='contained'>View File</Button></TableCell>
                                                <TableCell><Button onClick={()=>{navigate("/advisor-leads/advisor-nbfc-detail", {state:{item:item}})}} style={{backgroundColor:"#352F6C", height:30, width:120}} size='medium' variant='contained'>View NBFC</Button></TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </div>
        </div>
    )
}

export default AdvisorContestLeads