import React, { useContext, useEffect, useState } from "react";
import Styles from "./FeedbackCalls.module.css"
import { Box, CircularProgress, Input, Modal, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs } from "@mui/material";
import UserContext from "../../../context/UserData.context";
import { onValue, ref } from "firebase/database";
import { firebaseDatabase } from "../../../backend/firebaseHandler";
import FeedbackCollapseRow from "../../FeedbackCollapseRow/FeedbackCollapseRow.component";
import moment from "moment";

const FeedbackCalls = () => {

    const [userData, setUserData] = useContext(UserContext);
    const [value, setValue] = React.useState(0);
    const [allLeads, setAllLeads] = useState([])
    const [visibleLeads, setVisibleLeads] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        let tempAll = []
        let temp2 = []

        onValue(ref(firebaseDatabase, `INTERESTED_LEADS`), (snap) => {
            if (snap.exists()) {
                for (const uid in snap.val()) {
                    for (const number in snap.child(uid).val()) {
                        let item = snap.child(uid).child(number).val()
                        tempAll.push(item)
                    }
                }
                temp2 = tempAll.sort((a, b) => {
                    const date_1 = moment(a.interestedDate,"DD-MM-YYYY h:mm a")
                    const date_2 = moment(b.interestedDate,"DD-MM-YYYY h:mm a")
                    return moment(date_2).diff(date_1)
                })
                setAllLeads([...temp2])
                handleChange(null, 0)
                setLoading(false)
            }
        }, {onlyOnce:true})
    }, [])

    const handleChange = async (event, newValue) => {
        setValue(newValue)

        let temp = []

        for (const index in allLeads) {
            let item = allLeads[index]
            temp.push(item)
            // if ((newValue === 0 && !item.reviewStars) || (newValue === 1 && item.reviewStars)) {
            //     temp.push(item)
            // }
        }
        setVisibleLeads(temp)
    }
    
    
    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return (
        <div>
            <h1 style={{ color: "#352F6C" }}>Feedback Calls</h1>

            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" style={{marginTop:40}} >
                <Tab label="Pending" {...a11yProps(0)} />
                <Tab label="Reviewed" {...a11yProps(1)} />
            </Tabs>

            <div className={Styles.progressComponentContainer}>
                {
                    loading
                    ?
                    <CircularProgress className={Styles.progress} />
                    :
                    <Box>
                        <TableContainer>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>Name</TableCell>
                                        <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>Ph Number</TableCell>
                                        <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>Email ID</TableCell>
                                        <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>Advisor</TableCell>
                                        <TableCell style={{ backgroundColor: "rgba(1, 1, 1, 0)" }}></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody style={{ backgroundColor: "white" }}>
                                    {
                                        visibleLeads.map((item, index) => {
                                            return (
                                                
                                                <FeedbackCollapseRow item={item} index={index} />
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                }
            </div>
        </div>
    )
}

export default FeedbackCalls