import React, { useContext, useEffect, useState } from "react";
import Styles from "./RenewalRequestDetail.module.css"
import { useLocation, useNavigate } from "react-router-dom";
import UserContext from "../../context/UserData.context";
import moment from "moment";
import getHiDocumentMap from "../../workload/HiDocumentSet";
import { firebaseDatabase, firebaseStorage } from "../../backend/firebaseHandler";
import { getDownloadURL, uploadBytesResumable, ref as storageRef } from "firebase/storage";
import { onValue, ref, remove, set } from "firebase/database";
import { toast } from "react-toastify";
import { Alert, Box, Button, MenuItem, Modal, Select, Snackbar, Tab, Tabs } from "@mui/material";
import InputContainer from "../InputContainer/InputContainer.component";
import { FaExternalLinkSquareAlt } from "react-icons/fa";
import { MdOutlineFileDownload } from "react-icons/md";

const RenewalRequestDetail = () => {

    const location = useLocation()

    const [userData] = useContext(UserContext)
    const [application, setApplication] = useState(location.state.item);
    const [submitting, setSubmitting] = useState(false)
    const [value, setValue] = useState(0)
    const [address, setAddress] = useState({ line1: "", line2: "", city: "", state: "", pincode: "" })
    const [linkModal, setLinkModal] = useState(false)

    const [snackBar, setSnackBar] = useState({ visibility: false, severity: "", message: "" })
    const navigate = useNavigate()

    useEffect(() => {
        let tempObj = { ...application }

        tempObj.coverageStartDate = (!tempObj.coverageStartDate || tempObj.coverageStartDate === "") ? "" : moment(tempObj.coverageStartDate, "DD-MM-YYYY").format("YYYY-MM-DD")
        tempObj.coverageEndDate = (!tempObj.coverageEndDate || tempObj.coverageEndDate === "") ? "" : moment(tempObj.coverageEndDate, "DD-MM-YYYY").format("YYYY-MM-DD")
        tempObj.dob = (!tempObj.dob || tempObj.dob === "") ? "" : moment(tempObj.dob, "DD-MM-YYYY").format("YYYY-MM-DD")
        tempObj.nomineeDOB = (!tempObj.nomineeDOB || tempObj.nomineeDOB === "") ? "" : moment(tempObj.nomineeDOB, "DD-MM-YYYY").format("YYYY-MM-DD")
        tempObj.travelDate = (!tempObj.travelDate || tempObj.travelDate === "") ? "" : moment(tempObj.travelDate, "DD-MM-YYYY").format("YYYY-MM-DD")
        tempObj.nextCallDateTime = moment(tempObj.nextCallDateTime, "DD-MM-YYYY").format("YYYY-MM-DD")

        if (!application.applicantDocs) {
            tempObj.applicantDocs = [...getHiDocumentMap()["StudentInIndia"]]
        }
        setApplication({ ...tempObj })

        if (tempObj.indiaAddress !== "") {
            let addressArray = tempObj.indiaAddress.split(", ")
            let addLen = addressArray.length
            if (addLen > 5) {
                setAddress({ 
                    line1: addressArray.slice(0, addLen-5).join(", "), 
                    line2: addressArray[addLen-4], 
                    city: addressArray[addLen-3], 
                    state: addressArray[addLen-2], 
                    pincode: addressArray[addLen-1] 
                })
            } else if (addLen === 5) {
                setAddress({ 
                    line1: addressArray[0], 
                    line2: addressArray[1], 
                    city: addressArray[2], 
                    state: addressArray[3], 
                    pincode: addressArray[4] 
                })
            } else if (addLen === 4) {
                setAddress({ 
                    line1: addressArray[0], 
                    line2: "", 
                    city: addressArray[1], 
                    state: addressArray[2], 
                    pincode: addressArray[3] 
                })
            } else if (addLen < 4) {
                setAddress({ 
                    line1: tempObj.indiaAddress, 
                    line2: "", 
                    city: "", 
                    state: "", 
                    pincode: ""
                })
            }
        }

    }, [])

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleDocumentUpload = (item, folderName, index, key) => {
        const inputElement = document.createElement('input');
        inputElement.setAttribute('type', "file");
        inputElement.setAttribute('accept', 'application/pdf');

        inputElement.onchange = (event) => {
            const files = event.target.files;

            if (files[0].name.split(".")[1] !== "pdf") {
                setSnackBar({ visibility: true, severity: "warning", message: "Please upload a PDF file." })
            } else {
                const fileRef = storageRef(firebaseStorage, `HEALTH_INSURANCE_DOCS/${application.key}/${folderName}/${item.label}/${Date.now().toString()}.pdf`);
                setSubmitting(true)
                const uploadTask = uploadBytesResumable(fileRef, files[0], { contentType: 'application/pdf' });
                uploadTask.on('state_changed', (snapshot) => {

                }, (error) => {
                    setSnackBar({ visibility: true, severity: "error", message: "Something went wrong" })
                }, async () => {
                    const url = await getDownloadURL(fileRef);
                    item.url = url
                    let tempObj = { ...application }
                    tempObj[key][index] = { ...item }
                    setApplication({ ...tempObj })
                    setSubmitting(false)
                })
            }
        }
        inputElement.click();
    }

    const handelInIndia = (value) => {
        let tempObj = { ...application }

        tempObj.currentCountry = value
        if (value === "India") {
            if (application.planDetail.provider === "ICICI") {
                tempObj.applicantDocs = [...getHiDocumentMap()["StudentInIndiaICICI"]]
            } else {
                tempObj.applicantDocs = [...getHiDocumentMap()["StudentInIndiaBajaj"]]
            }
        } else {
            tempObj.applicantDocs = [...getHiDocumentMap()["StudentNotInIndia"]]
        }

        setApplication({ ...tempObj })
    }

    const handleSave = async () => {

        let tempObj = { ...application }

        tempObj.isProfileComplete = getProfileStatus()

        tempObj.indiaAddress = Object.values(address).join(", ")

        setApplication({ ...tempObj })
        tempObj.coverageStartDate = tempObj.coverageStartDate === "" ? "" : moment(tempObj.coverageStartDate, "YYYY-MM-DD").format("DD-MM-YYYY")
        tempObj.coverageEndDate = tempObj.coverageEndDate === "" ? "" : moment(tempObj.coverageEndDate, "YYYY-MM-DD").format("DD-MM-YYYY")
        tempObj.dob = tempObj.dob === "" ? "" : moment(tempObj.dob, "YYYY-MM-DD").format("DD-MM-YYYY")
        tempObj.nomineeDOB = tempObj.nomineeDOB === "" ? "" : moment(tempObj.nomineeDOB, "YYYY-MM-DD").format("DD-MM-YYYY")
        tempObj.travelDate = moment(tempObj.travelDate, "YYYY-MM-DD").format("DD-MM-YYYY")
        // tempObj.nextCallDateTime = moment(tempObj.nextCallDateTime, "YYYY-MM-DD").format("DD-MM-YYYY")

        setSubmitting(true)

        await set(ref(firebaseDatabase, `HI_RENEWAL_REQUESTS/${tempObj.phoneNumber}`), tempObj)
        setSubmitting(false)
        setSnackBar({ severity: "success", visibility: true, message: "Changes saved!" })
    }

    const getProfileStatus = () => {
        let isComplete = true

        if (!application.name) {
            isComplete = false
        }
        if (!application.dob) {
            isComplete = false
        }
        if (!application.visaCategory) {
            isComplete = false
        }
        if (!application.gender) {
            isComplete = false
        }
        if (application.isThisWhatsappNumber === "No" && !application.whatsappNumber) {
            isComplete = false
        }
        if (!application.desiredCountry) {
            isComplete = false
        }
        if (!application.desiredUniversity) {
            isComplete = false
        }
        if (!application.travelDate) {
            isComplete = false
        }
        // if (location.state.tab !== 1 && !application.coveragePeriod) {
        //     isComplete = false
        // }
        if (!application.currentCountry) {
            isComplete = false
        }
        if (!application.panNumber) {
            isComplete = false
        }
        if (!application.sponsorName) {
            isComplete = false
        }
        if (!application.emergencyNumber) {
            isComplete = false
        }
        if (!application.alternateEmail) {
            isComplete = false
        }
        if (!application.motherName) {
            isComplete = false
        }
        if (!application.passportNumber) {
            isComplete = false
        }
        if (!application.nomineeName) {
            isComplete = false
        }
        if (!application.nomineeRelation) {
            isComplete = false
        }
        if (!application.nomineeDOB) {
            isComplete = false
        }
        // if (!application.indiaAddress) {
        //     isComplete = false
        // }
        if (!address.line1) {
            isComplete = false
        }
        if (!address.line2) {
            isComplete = false
        }
        if (!address.city) {
            isComplete = false
        }
        if (!address.state) {
            isComplete = false
        }
        if (!address.pincode) {
            isComplete = false
        }
        if (application.haveUsAddress === "Yes" && !application.usAddress) {
            isComplete = false
        }

        if (!application.applicantDocs) {
            isComplete = false
        }

        for (const index in application.applicantDocs) {
            if (application.applicantDocs[index].url === "") {
                isComplete = false
            }
        }
        return isComplete
    }

    const sendLink = () => {

        if (!application.paymentLink) {
            setSnackBar({ severity: "warning", visibility: true, message: "Please upload payment link" })
            return
        }

        let tempObj = { ...application }

        tempObj.indiaAddress = Object.values(address).join(", ")
        tempObj.coverageStartDate = tempObj.coverageStartDate === "" ? "" : moment(tempObj.coverageStartDate, "YYYY-MM-DD").format("DD-MM-YYYY")
        tempObj.coverageEndDate = tempObj.coverageEndDate === "" ? "" : moment(tempObj.coverageEndDate, "YYYY-MM-DD").format("DD-MM-YYYY")
        tempObj.dob = tempObj.dob === "" ? "" : moment(tempObj.dob, "YYYY-MM-DD").format("DD-MM-YYYY")
        tempObj.nomineeDOB = tempObj.nomineeDOB === "" ? "" : moment(tempObj.nomineeDOB, "YYYY-MM-DD").format("DD-MM-YYYY")
        tempObj.travelDate = moment(tempObj.travelDate, "YYYY-MM-DD").format("DD-MM-YYYY")
        tempObj.nextCallDateTime = moment(tempObj.nextCallDateTime, "YYYY-MM-DD").format("DD-MM-YYYY")
        tempObj.paymentLinkSentDate = moment().format("DD-MM-YYYY h:mm a")
        tempObj.linkSentBy = userData.uid
        tempObj.status = "Link Sent"
        tempObj.subStatus = "Sent"

        if (location.state.tab === 0) {
            handleSentByAdvisorLead(tempObj, "HI_OP_USER_WITH_OPERATIONS_LEADS")
        } if (location.state.tab === 1) {
            handleSentByAdvisorLead(tempObj, "HI_CROSS_SALE_LEADS")
        } if (location.state.tab === 2) {
            handleWebsiteLeads(tempObj)
        } if (location.state.tab === 3) {
            handleInternalLeads(tempObj)
        }
    }

    const handleSentByAdvisorLead = (object, table) => {
        let tempObj = { ...object }

        tempObj.userType = "From Advisor"

        onValue(ref(firebaseDatabase, `ALL_LEADS_STATUS/${application.product}/${application.phoneNumber}`), async (snap) => {
            if (snap.exists()) {
                let lifelineObj = snap.val()
                lifelineObj.status = "Link Sent"

                let array = [...lifelineObj.lifeline]
                let arrayItem = {
                    actionBy: userData.uid,
                    status: "Link Sent",
                    subStatus: "Sent",
                    date: tempObj.paymentLinkSentDate,
                    ref: "HI_LINK_SENT_LEADS"
                }
                array.push(arrayItem)
                lifelineObj.lifeline = [...array]

                await set(ref(firebaseDatabase, `ALL_LEADS_STATUS/${tempObj.product}/${tempObj.phoneNumber}`), lifelineObj)
                await set(ref(firebaseDatabase, `HI_LINK_SENT_LEADS/${tempObj.assignedAdvisorUid}/${tempObj.phoneNumber}`), tempObj)
                await set(ref(firebaseDatabase, `HI_OP_USER_LINK_SENT_LEADS/${tempObj.phoneNumber}`), tempObj)
                await set(ref(firebaseDatabase, `HEALTH_INSURANCE_APPLICATIONS/${tempObj.phoneNumber}`), tempObj)
                await remove(ref(firebaseDatabase, `HI_WITH_OPERATIONS_LEADS/${tempObj.assignedAdvisorUid}/${tempObj.phoneNumber}`))
                await remove(ref(firebaseDatabase, `${table}/${tempObj.phoneNumber}`))
                setSubmitting(false)
                navigate(-1)
            }
        }, { onlyOnce: true })
    }

    const handleWebsiteLeads = (object) => {
        let tempObj = { ...object }

        tempObj.userType = "From Website"

        onValue(ref(firebaseDatabase, `ALL_LEADS_STATUS/${application.product}/${application.phoneNumber}`), async (snap) => {
            if (snap.exists()) {
                let lifelineObj = snap.val()
                lifelineObj.status = "Link Sent"

                let array = [...lifelineObj.lifeline]
                let arrayItem = {
                    actionBy: userData.uid,
                    status: "Link Sent",
                    subStatus: "Sent",
                    date: tempObj.paymentLinkSentDate,
                    ref: "HI_LINK_SENT_LEADS"
                }
                array.push(arrayItem)
                lifelineObj.lifeline = [...array]

                await set(ref(firebaseDatabase, `ALL_LEADS_STATUS/${tempObj.product}/${tempObj.phoneNumber}`), lifelineObj)
                await set(ref(firebaseDatabase, `HI_OP_USER_LINK_SENT_LEADS/${tempObj.phoneNumber}`), tempObj)
                await set(ref(firebaseDatabase, `HEALTH_INSURANCE_APPLICATIONS/${tempObj.phoneNumber}`), tempObj)
                await remove(ref(firebaseDatabase, `HI_FROM_WEBSITE_LEADS/${tempObj.phoneNumber}`))
                setSubmitting(false)
                navigate(-1)
            }
        }, { onlyOnce: true })
    }

    const handleInternalLeads = (object) => {
        let tempObj = { ...object }

        tempObj.userType = "Internal"

        onValue(ref(firebaseDatabase, `ALL_LEADS_STATUS/${application.product}/${application.phoneNumber}`), async (snap) => {
            if (snap.exists()) {
                let lifelineObj = snap.val()
                lifelineObj.status = "Link Sent"

                let array = [...lifelineObj.lifeline]
                let arrayItem = {
                    actionBy: userData.uid,
                    status: "Link Sent",
                    subStatus: "Sent",
                    date: tempObj.paymentLinkSentDate,
                    ref: "HI_LINK_SENT_LEADS"
                }
                array.push(arrayItem)
                lifelineObj.lifeline = [...array]

                await set(ref(firebaseDatabase, `ALL_LEADS_STATUS/${tempObj.product}/${tempObj.phoneNumber}`), lifelineObj)
                await set(ref(firebaseDatabase, `HI_OP_USER_LINK_SENT_LEADS/${tempObj.phoneNumber}`), tempObj)
                await set(ref(firebaseDatabase, `HEALTH_INSURANCE_APPLICATIONS/${tempObj.phoneNumber}`), tempObj)
                await remove(ref(firebaseDatabase, `INTERNALLY_CREATED_LEADS/${tempObj.phoneNumber}`))
                setSubmitting(false)
                navigate(-1)
            }
        }, { onlyOnce: true })
    }

    const handleHealthDeclaration = () => {
        if (!application.name) {
            toast.warn("Please enter the name")
            return
        }
        if (!application.travelDate) {
            toast.warn("Please enter the travel date")
            return
        }
        if (!application.coverageStartDate) {
            toast.warn("Please enter the coverage start date")
            return
        }
        if (!application.coverageEndDate) {
            toast.warn("Please enter the coverage end date")
            return
        }
        if (!application.nomineeName) {
            toast.warn("Please enter the nominee name")
            return
        }
        if (!application.nomineeRelation) {
            toast.warn("Please enter the nominee relation")
            return
        }
        if (!application.nomineeDOB) {
            toast.warn("Please enter the nominee DOB")
            return
        }

        let travelDate = moment(application.travelDate, "YYYY-MM-DD").format("DD-MM-YYYY")
        let startDate = moment(application.coverageStartDate, "YYYY-MM-DD").format("DD-MM-YYYY")
        let endDate = moment(application.coverageEndDate, "YYYY-MM-DD").format("DD-MM-YYYY")
        let nomineeDOB = moment(application.nomineeDOB, "YYYY-MM-DD").format("DD-MM-YYYY")
        let requestDate = moment().format("DD-MM-YYYY")
        let url = `https://sc-srm-api-nine.vercel.app/pdf/generate-health-declaration?name=${application.name}&travelDate=${travelDate}&effDate=${startDate}&toDate=${endDate}&requestDate=${requestDate}&nomineeName=${application.nomineeName}&nomineeDob=${nomineeDOB}&nomineeRelation=${application.nomineeRelation}&provider=${application.planDetail.provider}`
        console.log(url)

        window.open(url, "_blank")
    }

    return (
        <div>
            <div className={Styles.headerContainer}>

                <h1 style={{ color: "#352F6C" }}>Renewal request Detail</h1>

                <table style={{ backgroundColor: "#98fab2", padding: '16px 0', borderRadius: "12px" }}>
                    <tr>
                        <th style={{ fontSize: '0.9rem' }}>Current Plan</th>
                        <th style={{ fontSize: '0.9rem' }}>{application.planDetail.name === "SC Gold Plus" ? "Sum Insured" : "Deductable"}</th>
                        <th style={{ fontSize: '0.9rem' }}>Premium</th>
                    </tr>
                    <tr>
                        <td style={{ padding: "0 24px " }}>{application.planDetail.name}</td>
                        <td style={{ padding: "0 24px " }}>₹ {application.planDetail.deductible === "a" ? "--" : application.planDetail.deductible}</td>
                        <td style={{ padding: "0 24px " }}>₹ {application.planDetail.premium}</td>
                    </tr>
                </table>

                {/* <div className={Styles.qrMessageContainer}>
                    <p className={Styles.messageContainer}>Scan QR Code to make a call</p>
                    <div className={Styles.qrCodeContainer}>
                        <QRCode size={130} value={qrLink} viewBox={`0 0 256 256`} />
                    </div>
                </div> */}
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: 20, marginBottom: 40 }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" >
                    <Tab label="Applicant Details" {...a11yProps(0)} />
                    <Tab label="More Details" {...a11yProps(1)} />
                    <Tab label="Upload App Docs" {...a11yProps(2)} />
                </Tabs>
            </div>

            <div className={Styles.formDocContainer}>
                {
                    value === 0
                    &&
                    <div className={Styles.formContainer}>

                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Name</p>
                            <input value={application.name} onChange={(event) => { setApplication({ ...application, name: event.target.value })}} className={Styles.inputField} placeholder="Full Name" InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Date of Birth</p>
                            <input type="date" value={application.dob} onChange={(event) => { setApplication({ ...application, dob: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Email-Id</p>
                            <input value={application.emailId} onChange={(event) => { setApplication({ ...application, emailId: event.target.value }) }} className={Styles.inputField} placeholder="Email-Id" InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>

                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Visa Category</p>
                            <Select style={{ width: "100%" }} value={application.visaCategory || ""} onChange={(event) => { setApplication({ ...application, visaCategory: event.target.value }) }} disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                                <MenuItem value={"International Student on F1 Visa"} >International Student on F1 Visa</MenuItem>
                                <MenuItem value={"F-1 Student on OPT"} >F-1 Student on OPT</MenuItem>
                                <MenuItem value={"Student on J1 Visa"} >Student on J1 Visa</MenuItem>
                                <MenuItem value={"Non Student"} >Non Student</MenuItem>
                                <MenuItem value={"Dependents on F2/J2"} >Dependents on F2/J2</MenuItem>
                            </Select>
                        </div>

                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Gender</p>
                            <Select style={{ width: "100%" }} value={application.gender || ""} onChange={(event) => { setApplication({ ...application, gender: event.target.value }) }} disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                                <MenuItem value={"Male"} >Male</MenuItem>
                                <MenuItem value={"Female"} >Female</MenuItem>
                                <MenuItem value={"Other"} >Other</MenuItem>
                            </Select>
                        </div>

                        <div style={{ gridColumn: "1/2" }}>
                            <InputContainer value={application.phoneNumber} onChange={(event) => { }} className={Styles.inputField} placeholder="Phone Number" InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>

                        <div className={Styles.yesNoContainer} style={{ gridColumn: "1/2" }}>
                            <p>Is this whatsapp number?</p>
                            <p style={{ color: application.isThisWhatsappNumber === "Yes" ? "rgba(53, 47, 108, 1)" : "rgba(53, 47, 108, 0.5)", cursor: "pointer" }} onClick={() => { setApplication({ ...application, isThisWhatsappNumber: "Yes" }) }}>Yes</p>
                            <p>|</p>
                            <p style={{ color: application.isThisWhatsappNumber === "No" ? "rgba(53, 47, 108, 1)" : "rgba(53, 47, 108, 0.5)", cursor: "pointer" }} onClick={() => { setApplication({ ...application, isThisWhatsappNumber: "No" }) }}>No</p>
                        </div>

                        {
                            application.isThisWhatsappNumber === "No"
                            &&
                            <InputContainer value={application.whatsappNumber} onChange={(event) => { setApplication({ ...application, whatsappNumber: event.target.value }) }} className={Styles.inputField} placeholder="Whatsapp Number" InputProps={{ disableUnderline: true }} variant="standard" />
                        }

                        <div style={{ width: "100%", gridColumn: "1/2" }}>
                            <p className={Styles.label}>Country of Study</p>
                            <input value={application.desiredCountry} onChange={(event) => { setApplication({ ...application, desiredCountry: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>University</p>
                            <input value={application.desiredUniversity} onChange={(event) => { setApplication({ ...application, desiredUniversity: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Tentative Travel Date</p>
                            <input type="date" value={application.travelDate} onChange={(event) => { setApplication({ ...application, travelDate: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>

                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Coverage Period</p>
                            <input value={application.coveragePeriod} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>

                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Coverage Start Date</p>
                            <input type="date" value={application.coverageStartDate} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>

                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Coverage End Date</p>
                            <input type="date" value={application.coverageEndDate} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>

                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Current location?</p>
                            <Select style={{ width: "100%" }} value={application.currentCountry} onChange={(event) => { handelInIndia(event.target.value) }} disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                                <MenuItem value={"India"} >India</MenuItem>
                                <MenuItem value={"United States"} >United States</MenuItem>
                                <MenuItem value={"Other"} >Other</MenuItem>
                            </Select>
                        </div>

                    </div>
                }

                {
                    value === 1
                    &&
                    <div className={Styles.formContainer}>

                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>PAN number</p>
                            <input value={application.panNumber} onChange={(event) => { setApplication({ ...application, panNumber: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Sponser name</p>
                            <input value={application.sponsorName} onChange={(event) => { setApplication({ ...application, sponsorName: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Emergency contact number</p>
                            <input value={application.emergencyNumber} onChange={(event) => { setApplication({ ...application, emergencyNumber: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Alternate email-id</p>
                            <input value={application.alternateEmail} onChange={(event) => { setApplication({ ...application, alternateEmail: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Mother's name</p>
                            <input value={application.motherName} onChange={(event) => { setApplication({ ...application, motherName: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Passport number</p>
                            <input value={application.passportNumber} onChange={(event) => { setApplication({ ...application, passportNumber: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Nominee Name</p>
                            <input value={application.nomineeName} onChange={(event) => { setApplication({ ...application, nomineeName: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>

                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Nominee relation</p>
                            <input value={application.nomineeRelation} onChange={(event) => { setApplication({ ...application, nomineeRelation: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Nominee dob</p>
                            <input value={application.nomineeDOB} type="date" onChange={(event) => { setApplication({ ...application, nomineeDOB: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        <div style={{ width: "100%", gridColumn: "1/4" }}>
                            <p className={Styles.label}>Address line 1</p>
                            <input value={address.line1} onChange={(event) => { setAddress({ ...address, line1: event.target.value }) }} className={Styles.inputField} placeholder="Full Name" InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        <div style={{ width: "100%", gridColumn: "1/4" }}>
                            <p className={Styles.label}>Address line 2</p>
                            <input value={address.line2} onChange={(event) => { setAddress({ ...address, line2: event.target.value }) }} className={Styles.inputField} placeholder="Full Name" InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>City</p>
                            <input value={address.city} onChange={(event) => { setAddress({ ...address, city: event.target.value }) }} className={Styles.inputField} placeholder="Full Name" InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>State</p>
                            <input value={address.state} onChange={(event) => { setAddress({ ...address, state: event.target.value }) }} className={Styles.inputField} placeholder="Full Name" InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Pincode</p>
                            <input value={address.pincode} onChange={(event) => { setAddress({ ...address, pincode: event.target.value }) }} className={Styles.inputField} placeholder="Full Name" InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>

                        <div className={Styles.yesNoContainer} style={{ gridColumn: "1/2" }}>
                            <p>Do you have a US address?</p>
                            <p style={{ color: application.haveUsAddress === "Yes" ? "rgba(53, 47, 108, 1)" : "rgba(53, 47, 108, 0.5)", cursor: "pointer" }} onClick={() => { setApplication({ ...application, haveUsAddress: "Yes" }) }}>Yes</p>
                            <p>|</p>
                            <p style={{ color: application.haveUsAddress === "No" ? "rgba(53, 47, 108, 1)" : "rgba(53, 47, 108, 0.5)", cursor: "pointer" }} onClick={() => { setApplication({ ...application, haveUsAddress: "No" }) }}>No</p>
                        </div>

                        {
                            application.haveUsAddress === "Yes"
                            &&
                            <div style={{ width: "100%", gridColumn: "1/4" }}>
                                <p className={Styles.label}>US Address</p>
                                <input value={application.usAddress} onChange={(event) => { setApplication({ ...application, usAddress: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                            </div>
                        }

                    </div>
                }

                {
                    value === 2
                    &&
                    <>
                        <div className={Styles.documentListContainer}>
                            {
                                application.applicantDocs.map((item, index) => {
                                    return (
                                        <div className={Styles.documentItem}>
                                            <p onClick={() => { handleDocumentUpload(item, "Applicant Docs", index, "applicantDocs") }} style={{ color: item.url === "" ? null : "#12b004" }} className={Styles.documentName}>{item.name}</p>
                                            <FaExternalLinkSquareAlt onClick={() => { window.open(item.url, "_blank") }} color={item.url === "" ? "" : "#12b004"} style={{ cursor: "pointer" }} />
                                        </div>
                                    )
                                })
                            }
                        </div>
                        
                        {
                            application.currentCountry !== "India"
                            &&
                            <div className={Styles.downloadButton} onClick={()=>{handleHealthDeclaration()}}>
                                <p>Download Health Declaration</p>
                                <MdOutlineFileDownload color="white" />
                            </div>
                        }
                    </>
                }
            </div>

            <div className={Styles.buttonsContainer}>
                <Button disabled={submitting} onClick={() => { handleSave() }} sx={{ marginTop: '40px' }} variant='contained' className={Styles.button}>Save and Verify</Button>
                {
                    application.isProfileComplete
                    &&
                    <Button disabled={submitting} onClick={() => { setLinkModal(true) }} sx={{ marginTop: '40px' }} variant='contained' className={Styles.button}>Send Payment Link</Button>
                }
            </div>

            {/* Payment link modal */}
            <Modal open={linkModal} onClose={() => { setLinkModal(false) }} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div className={Styles.modalContainer}>
                        <p className={Styles.modalTitle}>Enter the below details</p>

                        <div style={{ width: "300px", display: "flex", flexDirection: "column", alignItems: "flex-start", marginTop: 20, flex: 1 }}>
                            <p className={Styles.label}>Payment link</p>
                            <input value={application.paymentLink} onChange={(event) => { setApplication({ ...application, paymentLink: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>

                        <div className={Styles.modalButtonContainer}>
                            <Button disabled={submitting} sx={{ backgroundColor: "rgba(53, 47, 108, 0.5)" }} onClick={() => { setLinkModal(false) }} variant='contained' className={Styles.secondaryModalButton}>Cancel</Button>
                            <Button disabled={submitting} onClick={() => { sendLink() }} variant='contained' className={Styles.primaryModalButton}>Submit</Button>
                        </div>
                    </div>
                </Box>
            </Modal>

            <Snackbar open={snackBar.visibility} autoHideDuration={3000} onClose={() => { setSnackBar({ visibility: false, message: "", severity: "" }) }}>
                <Alert onClose={() => { setSnackBar({ visibility: false, message: "", severity: "" }) }} severity={snackBar.severity} variant="filled" sx={{ width: '100%' }}>{snackBar.message}</Alert>
            </Snackbar>
        </div>
    )
}

export default RenewalRequestDetail