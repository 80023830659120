export default {
    "SC Essential": {
        "$150": {
            "22 years or under": {
                "annual": 764,
                "daily": 2.087431693989071
            },
            "23-26 years": {
                "annual": 1120,
                "daily": 3.060109289617486
            },
            "27-30 years": {
                "annual": 2575,
                "daily": 7.03551912568306
            },
            "31+ years": {
                "annual": 12287,
                "daily": 33.57103825136612
            }
        } ,
        "$500": {
            "22 years or under": {
                "annual": 671,
                "daily": 1.83333333333333
            },
            "23-26 years": {
                "annual": 992,
                "daily": 2.710382513661202
            },
            "27-30 years": {
                "annual": 2334,
                "daily": 6.377049180327869
            },
            "31+ years": {
                "annual": 11091,
                "daily": 30.30327868852459
            }
        }
    },
    "SC Basic": {
        "$100": {
            "22 years or under": {
                "annual": 995,
                "daily": 2.718579234972678
            },
            "23-26 years": {
                "annual": 1554,
                "daily": 4.245901639344262
            },
            "27-30 years": {
                "annual": 3497,
                "daily": 9.554644808743169
            },
            "31+ years": {
                "annual": 16920,
                "daily": 46.22950819672131
            }
        } ,
        "$500": {
            "22 years or under": {
                "annual": 949,
                "daily": 2.592896174863388
            },
            "23-26 years": {
                "annual": 1395,
                "daily": 3.811475409836066
            },
            "27-30 years": {
                "annual": 3158,
                "daily": 8.628415300546448
            },
            "31+ years": {
                "annual": 15254,
                "daily": 41.6775956284153
            }
        }
    },
    "SC Plus": {
        "$100": {
            "22 years or under": {
                "annual": 1486,
                "daily": 4.071232876712329
            },
            "23-26 years": {
                "annual": 2129,
                "daily": 5.832876712328767
            },
            "27-30 years": {
                "annual": 4990,
                "daily": 13.67123287671233
            },
            "31+ years": {
                "annual": 24155,
                "daily": 66.17808219178082
            }
        } ,
        "$500": {
            "22 years or under": {
                "annual": 1359,
                "daily": 3.723287671232877
            },
            "23-26 years": {
                "annual": 1931,
                "daily": 5.29041095890411
            },
            "27-30 years": {
                "annual": 4503,
                "daily": 12.33698630136986
            },
            "31+ years": {
                "annual": 21763,
                "daily": 59.62465753424658
            }
        }
    },
    "SC Elite": {
        "$100": {
            "22 years or under": {
                "annual": 1854,
                "daily": 5.079452054794521
            },
            "23-26 years": {
                "annual": 2641,
                "daily": 7.235616438356164
            },
            "27-30 years": {
                "annual": 6168,
                "daily": 16.8986301369863
            },
            "31+ years": {
                "annual": 29960,
                "daily": 82.08219178082192
            }
        } ,
        "$500": {
            "22 years or under": {
                "annual": 1689,
                "daily": 4.627397260273973
            },
            "23-26 years": {
                "annual": 2430,
                "daily": 6.657534246575342
            },
            "27-30 years": {
                "annual": 5554,
                "daily": 15.21643835616438
            },
            "31+ years": {
                "annual": 26942,
                "daily": 73.81369863013699
            }
        }
    },
    "SC Gold Plus": {
        "US or Canada": {
            "50,000": {
                "1-30": 3211,
                "31-60": 5644,
                "61-90": 8622,
                "91-120": 10748,
                "121-180": 12906,
                "181-240": 19354,
                "241-270": 19354,
                "271-365": 25187,

            },
            "100,000": {
                "1-30": 3988,
                "31-60": 7117,
                "61-90": 10173,
                "91-120": 12531,
                "121-180": 14919,
                "181-240": 22769,
                "241-270": 22769,
                "271-365": 29064,

            },
            "250,000": {
                "1-30": 5692,
                "31-60": 10916,
                "61-90": 15601,
                "91-120": 19510,
                "121-180": 23450,
                "181-240": 35170,
                "241-270": 35170,
                "271-365": 45345,

            },
            "500,000": {
                "1-30": 11422,
                "31-60": 17521,
                "61-90": 25038,
                "91-120": 34924,
                "121-180": 34924,
                "181-240": 54997,
                "241-270": 54997,
                "271-365": 68884,

            }
        },
        "Other": {
            "50,000": {
                "1-30": 1536,
                "31-60": 2687,
                "61-90": 4160,
                "91-120": 5201,
                "121-180": 6269,
                "181-240": 9373,
                "241-270": 9373,
                "271-365": 14001,

            },
            "100,000": {
                "1-30": 1923,
                "31-60": 3418,
                "61-90": 4833,
                "91-120": 6014,
                "121-180": 7225,
                "181-240": 10879,
                "241-270": 10879,
                "271-365": 16219,

            },
            "250,000": {
                "1-30": 2675,
                "31-60": 5286,
                "61-90": 7637,
                "91-120": 9448,
                "121-180": 11290,
                "181-240": 16916,
                "241-270": 16916,
                "271-365": 25307,

            },
            "500,000": {
                "1-30": 5773,
                "31-60": 6749,
                "61-90": 11081,
                "91-120": 16704,
                "121-180": 16704,
                "181-240": 25373,
                "241-270": 25373,
                "271-365": 37774,

            }
        }
    },
    "SC Prima 100": {
        "US or Canada": {
            "1-30": 1734,
            "31-60": 3077,
            "61-90": 4152,
            "91-120": 4798,
            "121-180": 6495,
            "181-240": 8427,
            "241-270": 9595,
            "271-365": 12549,
        },
        "Other": {
            "1-30": 831,
            "31-60": 1464,
            "61-90": 2091,
            "91-120": 2560,
            "121-180": 3087,
            "181-240": 3753,
            "241-270": 4650,
            "271-365": 6974,
        }
    },
    "SC Prima 200": {
        "US or Canada": {
            "1-30": 2244,
            "31-60": 4369,
            "61-90": 6212,
            "91-120": 7381,
            "121-180": 9350,
            "181-240": 12363,
            "241-270": 14025,
            "271-365": 17960,
        },
        "Other": {
            "1-30": 1046,
            "31-60": 2080,
            "61-90": 3026,
            "91-120": 3677,
            "121-180": 4441,
            "181-240": 5696,
            "241-270": 6704,
            "271-365": 9964,
        }
    },
    "SC Prima 300": {
        "US or Canada": {
            "1-30": 2854,
            "31-60": 5557,
            "61-90": 7902,
            "91-120": 9389,
            "121-180": 11893,
            "181-240": 15726,
            "241-270": 17840,
            "271-365": 22845,
        },
        "Other": {
            "1-30": 1330,
            "31-60": 2646,
            "61-90": 3848,
            "91-120": 4678,
            "121-180": 5649,
            "181-240": 7246,
            "241-270": 8527,
            "271-365": 12675,
        }
    },
    "SC Prima 500": {
        "US or Canada": {
            "1-30": 3329,
            "31-60": 6482,
            "61-90": 9219,
            "91-120": 10953,
            "121-180": 13875,
            "181-240": 18347,
            "241-270": 20812,
            "271-365": 26653,
        },
        "Other": {
            "1-30": 1551,
            "31-60": 3086,
            "61-90": 4490,
            "91-120": 5457,
            "121-180": 6591,
            "181-240": 8453,
            "241-270": 9948,
            "271-365": 14787,
        }
    },
    "SC Prima 500 Plus": {
        "US or Canada": {
            "1-30": 3329,
            "31-60": 6482,
            "61-90": 9219,
            "91-120": 10953,
            "121-180": 13875,
            "181-240": 18347,
            "241-270": 20812,
            "271-365": 49370,
        },
        "Other": {
            "1-30": 1551,
            "31-60": 3086,
            "61-90": 4490,
            "91-120": 5457,
            "121-180": 6591,
            "181-240": 8453,
            "241-270": 9948,
            "271-365": 14787,
        }
    },
    "SC Prima 750": {
        "US or Canada": {
            "1-30": 3913,
            "31-60": 7620,
            "61-90": 10835,
            "91-120": 12875,
            "121-180": 16309,
            "181-240": 21566,
            "241-270": 24464,
            "271-365": 31331,
        },
        "Other": {
            "1-30": 1824,
            "31-60": 3629,
            "61-90": 5279,
            "91-120": 6415,
            "121-180": 7747,
            "181-240": 9936,
            "241-270": 11694,
            "271-365": 17382,
        }
    },
    "SC Prima 1000": {
        "US or Canada": {
            "1-30": 4182,
            "31-60": 8142,
            "61-90": 11579,
            "91-120": 13758,
            "121-180": 17428,
            "181-240": 23045,
            "241-270": 26142,
            "271-365": 33478,
        },
        "Other": {
            "1-30": 1949,
            "31-60": 3877,
            "61-90": 5640,
            "91-120": 6854,
            "121-180": 8278,
            "181-240": 10617,
            "241-270": 12496,
            "271-365": 18574,
        }
    }
}