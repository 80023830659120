const getNbfcList = () => {
    const NbfcList = [
        {
            name: "Avanse",
            uid: "Avanse"
        },
        {
            name: "Credila",
            uid: "Credila"
        },
        {
            name: "InCred",
            uid: "InCred"
        },
        {
            name: "IDFC First Bank",
            uid: "IDFC"
        },
        {
            name: "Auxilo",
            uid: "Auxilo"
        },
        {
            name: "Tata Capital",
            uid: "TataCapital"
        },
        {
            name: "Union Bank",
            uid: "UnionBank"
        },
        {
            name: "Axis Bank",
            uid: "AxisBank"
        },
        {
            name: "ICICI",
            uid: "ICICI"
        },
        {
            name: "Prodigy",
            uid: "Prodigy"
        },
        {
            name: "Yes Bank",
            uid: "YesBank"
        },
        {
            name: "Physics Wallah",
            uid: "PhysicsWallah"
        }
    ]

    return NbfcList
}

export default getNbfcList