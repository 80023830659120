import React, { useState } from "react";
import Styles from "./FeedbackCollapseRow.module.css"
import { Collapse, TableCell, TableRow } from "@mui/material";
import QRCode from "react-qr-code";
import { FaStar } from "react-icons/fa6"
import InputContainer from "../InputContainer/InputContainer.component";

const FeedbackCollapseRow = ( {item, index} ) => {

    const [open, setOpen] = useState(false)
    const [rating, setRating] = useState(0)
    const [review, setReview] = useState("")
    
    return (
        <>
            <TableRow key={index.toString()} >
                <TableCell>{item.name}</TableCell>
                <TableCell>xxxxxxxxx{item.phoneNumber.substring(9, 12)}</TableCell>
                <TableCell>{item.emailId}</TableCell>
                <TableCell>{item.assignedAdvisorName}</TableCell>
                <TableCell style={{fontWeight: 700, color: "#352F6C", cursor:"pointer"}} onClick={()=>{setOpen(!open)}}>Detail</TableCell>
            </TableRow>

            <TableRow key={index.toString()}>
                <TableCell colSpan={13} style={{ padding: 0 }}>
                    <Collapse in={open} >
                        {
                            <div className={Styles.detailCodeContainer}>
                                <div className={Styles.detailContainer}>
                                    <div>
                                        <p className={Styles.label}>Country</p>
                                        <p className={Styles.value}>{item.desiredCountry}</p>
                                    </div>
                                    <div>
                                        <p className={Styles.label}>University</p>
                                        <p className={Styles.value}>{item.desiredUniversity}</p>
                                    </div>
                                    <div>
                                        <p className={Styles.label}>Interested Date</p>
                                        <p className={Styles.value}>{item.interestedDate?item.interestedDate.split(" ")[0]:""}</p>
                                    </div>
                                </div>
                                
                                <div className={Styles.codeFormContainer}>
                                    <QRCode size={130} value={"This is a sample qr code just to test the UI."} viewBox={`0 0 256 256`} />
                                    <div className={Styles.formContainer}>
                                        <div className={Styles.starsContainer}>
                                            {
                                                [1, 2, 3, 4, 5].map((item) => { return(
                                                    <FaStar color={rating>=item?"#FFD700":"#bfbcbb"} size={30} onClick={()=>{setRating(item)}} style={{cursor:"pointer"}} />

                                                ) })
                                            }
                                        </div>

                                        <div style={{ width: "100%" }}>
                                            <p className={Styles.label}>Review</p>
                                            <input value={review} onChange={(event) => { setReview(event.target.value) }} className={Styles.inputField} placeholder="Review" InputProps={{ disableUnderline: true }} variant="standard" />
                                        </div>
                                    </div>
                                    <button className={Styles.submitButton}>Submit</button>
                                </div>
                            </div>
                        }
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    )
}

export default FeedbackCollapseRow