import React, { useContext, useEffect, useState } from "react";
import UserContext from "../../../context/UserData.context";
import Styles from "./AdvisorNewLeadsIncognito.module.css"
import { useNavigate } from "react-router-dom";
import { Box, CircularProgress, Input, Modal, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs } from "@mui/material";
import { ref, onValue, query, orderByChild, limitToFirst, limitToLast, equalTo } from 'firebase/database'
import { IoCall, IoSearch, IoTime } from "react-icons/io5"
import { firebaseDatabase } from "../../../backend/firebaseHandler";
import { animate, useMotionValue, useTransform, motion } from "framer-motion";
import _ from "lodash";

const AdvisorNewLeadsIncognito = ({activeList, userData}) => {

    const navigate = useNavigate();
    const [visibleList, setVisibleList] = useState([])
    const [value, setValue] = React.useState(0);
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        handleChange(null, 0)
    }, [])

    const handleChange = (event, newValue) => {
        setValue(newValue)
        setLoading(true)
        let temp = []
        let dbRef = null
        let reference = ref(firebaseDatabase, `INCOGNITO_NEW_LEADS/${userData.uid}`)

        if (newValue === 0) {
            dbRef = query(reference, orderByChild("subStatus"), equalTo("From Admin-Yet to Call"), limitToLast(100));      
        } else if (newValue === 1) {
            dbRef = query(reference, orderByChild("subStatus"), equalTo("Rescheduled"), limitToLast(50)); 
        } else if (newValue === 2) {
            dbRef = query(reference, orderByChild("subStatus"), equalTo("From Admin-Called"), limitToLast(50)); 
        }

        onValue(dbRef, (snap) => {
            if (snap.exists()) {
                for (const key in snap.val()) {
                    let item = snap.child(key).val()
                    temp.push(item)
                    // if ((newValue === 0 && (item.subStatus !== "Future Intake" && item.subStatus !== "Rescheduled")) || (newValue === 1 && item.subStatus === "Rescheduled")) {
                        
                    // }
                }
            } 
            setVisibleList(temp)
            setLoading(false)
        }, {onlyOnce:true})
    }

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return (
        <div>
            <div className={Styles.tabsSearchContainer}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" >
                    <Tab label="All" {...a11yProps(0)} />
                    <Tab label="Rescheduled" {...a11yProps(1)} />
                    <Tab label="Pending" {...a11yProps(2)} />
                </Tabs>

                <SearchBar uid={userData.uid} navigate={navigate} />

            </div>
            <div className={Styles.progressComponentContainer}>
                {
                    loading
                    ?
                    <CircularProgress className={Styles.progress} />
                    :
                    <Box>
                        <TableContainer>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>UID</TableCell>
                                        <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>Name</TableCell>
                                        <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>Ph Number</TableCell>
                                        <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>Email ID</TableCell>
                                        <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>Country</TableCell>
                                        <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>Type</TableCell>
                                        <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>Source</TableCell>
                                        {
                                            value === 2
                                            &&
                                            <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>Last Call Date</TableCell>
                                        }
                                        <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>Assg. Date</TableCell>
                                        <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>Status</TableCell>
                                        {
                                            value === 1
                                            &&
                                            <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>Next Call on</TableCell>
                                        }
                                        <TableCell style={{ backgroundColor: "rgba(1, 1, 1, 0)" }}></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody style={{ backgroundColor: "white" }}>
                                    {
                                        visibleList.map((item, index) => {
                                            return (
                                                <TableRow key={index.toString()} >
                                                    <TableCell>{item.key}</TableCell>
                                                    <TableCell>{item.name}</TableCell>
                                                    <TableCell>xxxxxxxxx{item.phoneNumber.substring(9, 12)}</TableCell>
                                                    <TableCell>{item.emailId}</TableCell>
                                                    <TableCell>{item.desiredCountry}</TableCell>
                                                    <TableCell>{item.incognitoType}</TableCell>
                                                    <TableCell>{item.source}</TableCell>
                                                    {
                                                        value === 2
                                                        &&
                                                        <TableCell>{item.lastCallDate}</TableCell>
                                                    }
                                                    <TableCell>{item.newFromAdminDate.split(" ")[0]}</TableCell>
                                                    <TableCell>{item.subStatus.includes("Yet to Call") ? "Yet to Call" : item.subStatus.includes("Forwarded") ? "Forwarded" : "Called"}</TableCell>
                                                    <TableCell>
                                                        {
                                                            value === 1
                                                            &&
                                                            <TableCell>{item.nextCallDateTime}</TableCell>
                                                        }
                                                    </TableCell>
                                                    <TableCell><IoCall size={20} color="#352F6C" onClick={() => { navigate(`/advisor-incognito-leads/advisor-incognito-lead-detail?currentSelectedTab=0`, { state: { item: item } }) }} style={{ cursor: "pointer" }} /></TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                }
            </div>
        </div>
    )
}

const SearchBar = ({ uid, navigate }) => {
    const [searchWord, setSearchWord] = useState("");
    const [loading, setLoading] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const modalAnimation = useMotionValue(0);
    const [searchResults, setSearchResults] = useState([])

    const openModal = () => {
        setModalVisible(_ => true);
        animate(modalAnimation, 10)
    }

    const closeModal = () => {
        animate(modalAnimation, 0).then(() => {
            setTimeout(() => {
                setModalVisible(_ => false);
            }, 200)
        })

    }

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            handleSearch();
        }
    }

    const handleSearch = async () => {
        try {
            setLoading(true);
            const searchHelperRef = ref(firebaseDatabase, `/INCOGNITO_SEARCH_HELPER/${searchWord}`);
            onValue(searchHelperRef, async (dataSnapshot) => {
                if (dataSnapshot.exists()) {
                    const list = dataSnapshot.val();
                    const phoneNumbers = Object.keys(list);

                    const promises = [];
                    for (const number of phoneNumbers) {
                        const leadPromise = new Promise((resolve, reject) => {
                            const newLeadsRef = ref(firebaseDatabase, `/INCOGNITO_NEW_LEADS/${uid}/${number}`);
                            onValue(newLeadsRef, async (dataSnapshot) => {
                                const entry = await dataSnapshot.val();
                                resolve(entry);
                            }, { onlyOnce: true })
                        })
                        promises.push(leadPromise);
                    }

                    let searchedLeads = await Promise.all(promises);
                    searchedLeads = searchedLeads.filter(item => item !== null);
                    if (searchedLeads.length >= 0) {
                        openModal();
                    }
                    setSearchResults(searchedLeads)
                    setLoading(false);
                }
            }, { onlyOnce: true })

        } catch (err) {

        }
    }

    const contentStyle = {
        height: useTransform(modalAnimation, [0, 10], [0, (0.8 * (window.screen.height))])
    }

    return (
        <>
            <Input
                disableUnderline={true}
                style={{ backgroundColor: "white", borderRadius: 5, padding: 5 }}
                value={searchWord} onChange={(event) => { setSearchWord(event.target.value) }}
                onKeyDown={handleKeyDown}
                startAdornment={<IoSearch style={{ marginRight: 12, marginLeft: 4 }} size={24} />}
                endAdornment={<CircularProgress size={12} style={{ marginRight: 12, opacity: loading ? 1 : 0 }} />}
                size='small'
                placeholder='Last 3 digit of phone number'

            />
            <Modal open={modalVisible} onClose={closeModal}  >
                <div className={Styles.modalContentContainer}>
                    <motion.div style={contentStyle} className={Styles.modalContent}>
                        <h2>Search Results</h2>
                        <p>You got {searchResults.length} results</p>

                        <div>
                            <Box sx={{ marginTop: '12px' }}>
                                <TableContainer>
                                    <Table stickyHeader>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>UID</TableCell>
                                                <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>Name</TableCell>
                                                <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>Ph Number</TableCell>
                                                <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>Email ID</TableCell>
                                                <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>Country</TableCell>
                                                <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>Assg. Date</TableCell>
                                                <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>Status</TableCell>
                                                <TableCell style={{ backgroundColor: "rgba(1, 1, 1, 0)" }}></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody style={{ backgroundColor: "white" }}>
                                            {
                                                searchResults.map((item, index) => {
                                                    return (
                                                        <TableRow key={index.toString()} >
                                                            <TableCell>{item.key}</TableCell>
                                                            <TableCell>{item.name}</TableCell>
                                                            <TableCell>xxxxxxxxx{item.phoneNumber.substring(9, 12)}</TableCell>
                                                            <TableCell>{item.emailId}</TableCell>
                                                            <TableCell>{item.desiredCountry}</TableCell>
                                                            <TableCell>{item.newFromAdminDate.split(" ")[0]}</TableCell>
                                                            <TableCell>{item.subStatus.includes("Yet to Call") ? "Yet to Call" : item.subStatus.includes("Forwarded") ? "Forwarded" : item.lastCallDate.split(" ")[0]}</TableCell>
                                                            <TableCell><IoCall className={Styles.callIcon} onClick={() => { navigate(`/advisor-incognito-leads/advisor-incognito-lead-detail?currentSelectedTab=0`, { state: { item: item } }) }} style={{ cursor: "pointer" }} color="#352F6C" size={20} /></TableCell>
                                                        </TableRow>
                                                    )
                                                })
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </div>
                    </motion.div>


                </div>
            </Modal>
        </>
    )
}

export default AdvisorNewLeadsIncognito